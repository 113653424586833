import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #5 What process is undertaken by whom?</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>What processes are undertaken by whom? Critical piece of information again having a good understanding about business and how work is done in the organisation, who does what, what information they process with. At the end of the day we're trying to protect information and that's our priority so what are the information assets and not just those information assets we're also talking about what people do because people become assets themselves to an organisation. </p>

                  <p>If you have a good understanding about all the business processes they may not be technology-based there could be manual processes, it might be just something where somebody comes to deliver something to your organisation, where do they enter the building where they leave the building and is there any CCTV? It could be something related to physical security it might just be have you got this contract in place. Who does what where, when, and why? </p>

                  <p>It's the basics you have to know what and okay once you understand the process, the next thing you want to do is find out where information systems are employed because yeah there may be a lot of physical and practical and administrative processes in place but chances are you're going to be using a lot of information systems in this day and age. What systems are employed? and what are the dependencies? Like you just mentioned earlier it might just be it might not just be your systems that you have in the house and now a lot of the technology is employed and outsourced.</p>

                  <p>Our data centers could be anywhere in the world so who do you rely on to keep your systems running and the data secure and how long does it take? How much time does it take? That's another thing to understand, the processes, understand the timeline of data being processed, and if you're talking about things like backup tapes or consumable items how many are consumed? The last thing you want to do is run out of backup tapes when you when in the middle of a critical backup, or, some backups tapes get damaged and you need to can you get replacements.</p>

                  <p>It could be something as simple as that. Someone didn't think through or have spares located in a secure area. Do we have only what we need or do we have spares again
                  going back to the business continuity plan it does depend a lot on having to be resilient, you have to have backup systems backup processes and spare parts in place so
                    do we have what we need so if you understand that, it's going to help you really build a robust business continuity plan.</p>
                  {/*}    <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+5+Who.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+5+Who.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
