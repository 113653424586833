import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #3 Assess, Evaluate & Screen</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Assess and evaluate. Over to you Mustafa.</p>

                  <p>Yes well, you've got information in front of you, for instance, let's look at the example
                  of the background checks or looking at the risks that may come from going to the wrong people,
                  if you take all the information that's available to you someone has to make a call, someone
                  a decision and you also have to look at when you're giving somebody a role so when we talk
                  about human factors here we're talking about reducing the risk not just blaming the human as
                  being the weak link. It's an argument that's been made over and over again since information
                  security books have been written it's true in some sense but we have to now look at shifting
                    the blame.</p>

                  <p>It's not just the person. We have to assess what are we doing? What are our processes? Who
                  do we delegate ownership to? Who do we delegate data custodianship? Who's going to look after
                  the data on a day-to-day basis and are they the right people to do that and what sort of
                  information should they be accessing what shouldn't they be accessing and the roles have to
                  be thought out early, thoroughly and then the system has to be built around that and things
                  change in enterprises things are always changing so you have to keep this up to date they're
                    all well and good.</p>

                  <p>Setting up the first time you might create a brilliant id and access management system
                  commissions and those systems over time can get outdated, your organization changes, what you
                  do changes, and have you been using the principle of least privilege? If it's irrelevant now
                  that there's some change in the organization? Also it may be more costly to implement things
                  like job segregation and job rotation and some of these concepts that we might have read about
                  theories, information security concepts that we always come across. Implementation could be
                  expensive but you have to always make the case, the business case that how much would it cost
                  if you didn't do this. What's the potential risk? And why is it worth investing in these
                  processes and making sure that becomes part of what you do? That's just one aspect if they
                  take one example looking at the human side of risk and assessed and evaluated that but this
                  is applicable to any type of risk so like number six says determining candidate screening and
                  that's just one thing that could be done in HR the type of things vary so we can go into a lot
                    more detail with that.</p>

                  <p>But yeah and there's the page number shown there.</p>

                  <p>The other thing about candidate screening and all of these points, especially when it comes
                  to staff and we kind of kicked it off the number four there with human factors is that as we
                  set the scene for employees we let them know that here we care about security because the
                  first thing we do is we start having a conversation with them and part of the screening
                  process is actually to get them to sign agreements and to take them through those agreements
                  but also look into their background to know that they are you know worthy of employment as
                  well and we ask their permission for those searches to take place but it starts a whole
                  conversation about security occurs here. We take care and the employee it is left their
                  imagination but we could enforce it if we wanted it's easy to do, but the employee gets
                  the feeling rightly, hopefully, that everybody here has been screened and meticulous care
                  is given to understanding their role, the part that they play, and even how we segregate.
                  Now we have job rotation schemes and we can tell them why there's absolutely nothing hidden
                  here this is all perfectly reasonable and understandable and very valuable to maintaining
                    security.</p>
                  {/* //     <ReactPlayer
                  //         className='react-player ml-2 mt-0 mb-2'
                  //     url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-5-6+Final.mp4'
                  //         width='22rem'
                  //         controls={true}
                  // /> */}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-5-6-1.mp3'
                      width='22rem'
                      controls={true}
                  />

              </div>
          </article>



      </div>

  )
}

export default Qsguide;
