import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #2 security and governance</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>The second point here is security and governance. So you know certainly in the larger firms but increasingly in smaller firms, organizations, enterprises are adopting certain plans, certain Governance concepts and saying well hang on at a board level this is what we've decided to run a business according to this.</p>

                  <p>If and when you find that that applies you should really get to know that Governance obviously, you should in any case but you should really try to view it from a perspective of what does it really mean to you in terms of your role because it can help you determine Scope and it can help you acquire the levers.</p>

                  <p>I mean, honestly what we know in most cases is that the entire budget in many cases, anyway we hear it a lot, the entire budget for security in some organization is the salary of the security consultant or professional. Obviously, it's not going to be the same as if you're running a SOC it's going to be much much higher but, or you're really established in security but in some companies some quite big brand names the total budget is the salary of the security officer.</p>

                  <p>So that's quite difficult and so it's very easy to be given a task with a kind of a poison chalice because if you're the entire budget and yet, you spot that there's massive weaknesses and there just ain't any more money what can you do?</p>

                  <p>So you have to have or be able to find things like Governance, in there to kind of provide an umbrella and or protection, it basically enables you to go back to define a scope and you say however this is the Scope this should be in Scope but it's not in Scope, in other words, you need a budget but there's no budget available therefore this level of protection according to the requirement of Governance as well as the requirement of security isn't possible. So this boosts, you should be able to boost your communication to the board that ultimately they take that responsibility.</p>

                  <p>You've advised them of the shortfall of facts exist if that's the situation and they need to be able to deal with it any thoughts on that Mustafa?</p>

                  <p>No, you've covered it quite well the only thing I can add is basically it's always the GRC framework for Governance, Risk, and Compliance so hence we do focus a bit on Governance and what is the responsibility of the people at the top of an organization when it comes to putting management in place for security and we also talk about building that relationship with senior management and being able to make the case for cyber security where the budget might need to be increased if there isn't one. So yeah it's quite an important part.</p>
                  <p>
                    

                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                          url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231+1+Always+Start+With+Scope++2+Security+%2B+Governance.mp4'
                          width='22rem'
                          controls={true}
                      />

                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                          url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/1+Scope+Questions/Scope+Questions-1.m4v'
                          width='22rem'
                          controls={true}
                      />
                </p>
              </div>
          </article>
          <Footer />  

        
      </div>    
 
  )
}

export default Qsguide;
