import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #1 The value of Governance, Risk & Compliance</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Ensure governance risk and compliance are engaged. These three functions are key when it comes to protecting organisations, in larger organisations especially, you'll have different people managing these areas. There'll be someone overseeing Governance and the main part of Governance is oversight and making sure management is in place to manage Risk and to manage Compliance so you probably have seen if you look at ISACAs point of view and the ISO27001 that's all the ISO security standards, they all want management to be separate from Governance and it's a key thing to have in an organisation because if the Governance side starts micromanaging they lose their focus, they're not doing what they're supposed to do and if managers have to start doing the Governance as well it's basically a conflict of interest. They're really watching what they're doing themselves and it doesn't make sense. They both have to be in place and when it comes to Risk and Compliance you have to have a good understanding of Governance, Risk, and Compliance in your organisation.</p>

                  <p>It's easy to have a bias if you're from a technical background for instance and you're dealing with the risk you're going to start looking at the technical side of risk rather than the physical security necessarily or maybe you might not focus more on the processes or the admin side of security, you'll find that there is a bias and if someone comes from a management side they'll be focusing more on the management, or if you've got someone from Governance, yes they might be focusing on Governance, but are they putting in place and delegating good management? So these all go together, you'll see this often. Governance, Risk, and Compliance often merged together and it's for a good reason but if it's not well managed it can go wrong. So the first thing is to make sure that you have good oversight of risk and compliance and good governance in place to do that.</p>

                  <p>Talk about bias like Mustafa just said if you come from a Governance background then your bias may be Governance implied if you come from a Compliance background your bias because you have more knowledge of Compliance is likely to be more Compliant. If you're coming at it just from Risk and you have a technology background then you know we're gonna quite often expect to see that a lot of the solutions that you put forward are likely to be the technology you know principally not that there's anything wrong with any of those three things it's just that the weighting will weigh according to your past experience which is we're referring to that as the bias.</p>

                  <p>What's very hard to do is because of your experiences in one area you need to get up to or try to balance the other two areas or the other areas that you're less familiar with so you need to increase your knowledge of the G or the R or the C so you're much more familiar with them than you would otherwise be, because you've always worked in this or mainly worked in a certain area of the business beforehand. So, when we get to, you know, understanding evaluation in the process we don't just evaluate from a technical perspective, we evaluate from a Risk perspective, but also a Compliance perspective, and a Governance perspective and all three need to be kind of agreed or in balance. What you might find for instance and how this can help you, for instance, is that and this is a no-cost win potentially is that you might identify risk but you might identify as a cost associated and obviously, you put a budget forward and someone might say no we're not signing that off we've got a budget we've spent the budget we don't have a new budget okay and purely on financial reasoning that makes sense okay you know no budget and when do you have a budget well next year but if you had a really good understanding of Compliance and Governance you'd be able to refer them to the fact that actually there's an audit coming up actually the organisation have already agreed to this type of Governance and that this is a serious situation that we need to protect against. So you've got two more big hulking great big reasons as to why they should go and find the budget which obviously is going to all add up to the same thing it's not about you skewering them to get a budget, it's about you ensuring you get the budget required, to deliver on what the company expects to deliver for itself and to give itself more protection.</p>

                  <p>Governance and Compliance can really help you if you're looking after Risk and all we've done really is to try to balance out the natural bias that exists based on past experience, worth the effort in other words.</p>
                  <p>Correct it's about Governance being done at the right level of the organ at the organization the strategy is going to fail the program is going to fail the operations are going to be shambolic so you have to get it at the right level in the organisation and you have to get senior management to take security seriously. The wins could be short term. I mean if you're not protected you suddenly got attacked bang, there's an opening and our objective is to close those gaps, and to do it in balance with Governance. Risk, and Compliance to use those three things.</p>
                  {/*}    <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+1+GRC.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+1+GRC_1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
