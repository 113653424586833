import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #1 Triage To Prioritise</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Using triage to ensure you do the right thing at the right time, how to prioritize. Maybe
                    you'd like to pick this up Mustafa?</p>

                  <p>Following on from the first domain... In the first domain, we talked about
                  scope. Once you know what's in scope, what you're dealing with, then you need to prioritize
                  and sometimes you can do that by determining how that would influence or impact your enterprise
                  so there's a lot of business impact analysis that needs to be done and hopefully you'll have
                  some idea about the businesses most critical areas, most sensitive data that you might be
                  dealing with. So you're going to look at the incident or the issue that you're trying to
                  deal with. Is it related to the most critical part of the organization? So how serious is
                  that breach or that attack or a possibility of a threat escalating to a level where it's
                    going to be devastating to the organisation?</p>

                  <p>We're going to look at what needs to be done and we're going to start looking at putting
                  something in place to mitigate quickly and then deal with the issue in more detail as we go
                  along the first thing we have to do is to make sure whatever we do to start with does not cause
                  more problems so we talk about secondary risk and we mention it here as well sometimes when
                  you're trying to deal with something and you're not it's not planned or you're not doing it
                  in a structured way in a way that you have a process in mind, you could make things worse and
                  that's what we don't want to do when we're prioritising. The first thing is to make sure what
                   you're doing to deal with the issue not make it worse so for that we can do a lot of things
                   beforehand and this is the part of the long longer-term architectural planning of security
                     for the organization so yeah anything to add. David?</p>

                  <p>Yeah, the business impact analysis is a classic process we often refer to as the BIA we
                  actually deal with that a little bit more in the next domain which we'll get to but just to
                  point out that there is a BIA employee questionnaire that you can download from the resources
                  section of the portal which is all about helping you to make decisions about what is likely
                  to be the highest priority because you have one view as the CISO but your staff and colleagues
                  may have a different view, your customers again another view, suppliers another view...
                  Everyone's got a different view on all these things and what we have to do is obviously
                  we've got to balance it all and it's through this balance and understanding of what the
                  business is that enables us to make decisions so business impact analysis, who does it
                     impact.</p>

                  <p>Ultimately we look at things like cost and other things so most importantly there is to
                  check that in the process of prioritizing we don't actually make a secondary or risk
                  opportunity and probably most importantly before anything else is to ideally prepare for
                  risk try to deal with risk before they occur so the BIA, the purpose of the analysis of
                  potential risk and the business impacts that may occur is to try and prioritize using the
                  process of Triage we may end up saying we prefer to prioritize in certain ways. This will
                  potentially save the company money, or the enterprise money, and make your life easier as
                  well especially if other people know what has been determined under the Triage process
                  because then it actually helps you to hand over to maybe an external department, or an
                  IT department, or give them instructions as to also how to deal with certain situations
                   or your preferences and again this can just help speed the recovery process which of
                   course is what we want more hands that know what to do more effective collectively
                      we're going to be in fixing issues</p>
                      {/*}<ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-1-2+Final.mp4'
                          width='22rem'
                          controls={true}
                      />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-1-2-1.mp3'
                      width='22rem'
                      height='5rem'
                      controls={true}
                      light={false}
                  />
              </div>
          </article>

          <Footer />

      </div>

  )
}

export default Qsguide;
