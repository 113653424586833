import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #8 Human Values, SPOF & Essential Computations</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>SPOF, a single point of failure, we talk about human assets again. I talked about it very
                  briefly earlier but if you don't have job rotation in place, it's quite possible that your
                  human assets can actually be a Single Point Of Failure if someone is absent or unable to
                    fulfill their duties or the only person with the appropriate skills.</p>

                  <p>I've seen this occur in manufacturing where there's certain processes that certain people have
                  a knowledge of how to do certain things. Usually to do with heating up metal and freezing plastic
                  and when one is contracted and the other is expanded that's the moment when you can put them
                  together but how you heat and how you cool has become an art and obviously if that person is
                  detained, inactive, unavailable suddenly that part of production, operations can't happen
                  anymore creating a single point of failure so we need to look out for those in these crucial
                    parts of the operations and the solution really is cross-training.</p>

                  <p>Now some members of staff obviously will try and protect their domain of expertise and not want
                  to share because they may feel that they may be losing their value or importance to the business
                  and this obviously can be a concern to them and should definitely be a concern to us so there's
                  an area we definitely need to deal with we need to uncover those human single points of failure
                  and put in solutions. Pretty much a no-cost solution but actually a big risk area especially well for service as well
                    as production companies but especially production companies. </p>

                  <p>Essential computations so there's
                  a bunch of those which if you're familiar with security, you're probably familiar with things
                  like ALE and SLE - Single Loss Expectancy and Annual Loss Expectancy and assessing the cost of
                    safeguards according to those ALE and SLE and also if you are familiar with RTO and RPO.</p>

                  <p>Mustafa, I wonder if you could take us through book and I'm sure a lot of people are
                  day-to-day be familiar with the Recovery Time Objectives and Recovery Point Objects a
                    function might be critical to the organization, how long can you go without?</p>

                  <p>That's where you have to decide and all this is going to feed into the disaster and business
                  continuity plan. You have to do these calculations and how often do you back up? How critical
                    is the data? Again it depends on how much you rely on that information.</p>

                  <p>So all these calculations have to be done then and generally, there's the concept of
                  proportionality that you don't spend you generally don't spend more on the controls to
                  protect the data then the data is worth itself or the assets itself so you have to calculate
                  those costs and make sure that you are efficient by the security if you implement it
                  efficiently you save the company money, the organisation money, chances are the budgets
                    are going to be easier to get because the value can be seen.</p>

                  <p>If there's a lot of wastage going on in information security that's where budgets become
                  a problem as well. Because if it's just you know the cost center or then something that can
                  actually benefit the organization by making it secure it's more difficult to make the case
                    and get the appropriate funding in.</p>

                  <p>Just going back to the point 15 human single points of failure and like you mentioned it's
                  a no-cost solution, just make sure someone who's already in your employment and there's a
                  very limited cost to implement. A lot of the things that we talk about they are easy to do
                  and they're so cost-effective they don't cost you that much and they bring you a lot of
                  benefits and they mitigate a lot of risk, and it's these type of things are getting missed
                  often and over and over again so you really need to look at are we doing the basics and the
                  basics don't even need to cost the company millions to implement if we can get those in
                  place you won't go far wrong in fixing the problems that we keep having in security and
                    the type of things you see.</p>

                  <p>A simple thing like backup... is it being done are they being tested?</p>

                  <p>Why is there such a global issue with the ransomware it shouldn't be the case if there are
                  good backups in place. It's the basics that are being missed and not a lot more money needs
                    to be spent just to secure organisation.</p>
                    {/*}  <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-15-16+Final.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-15-16-1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
