import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #9: Data = Value + Location</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>We've touched on both, this is the value and location of things but also the value and
        the location or the path of data. This is definitely a calculation or an assessment of an
        appreciation you should have in order to understand where best prioritise security so you
        might find common locations or worse older locations appearing that shouldn't be appearing.
        Again you may want to change the process, or cut out steps in a process in order to manage
        the security in terms of location of storage or storage and data in motion and then lastly
        16 categorised data.</p>

                  <p>Categorised data is very important because when you categorise the data, in the process
                  you can set rules of access, rules of processing data, file size, number of records
                  distributed in any one go, and more. These are all designed these categorizsation procedures
        can be designed in order to minimise potential data losses.</p>

                  <p>Classic categorisation creates a hierarchy: classified, secret, top secret. Simple
                  categorisation and in each case you provide lower and lower amounts of data visibility to
                  be passed to operators. One of the things that you can put yourself in this is who has
        access to what.</p>

                  <p>Any thoughts on this Mustafa? You've covered quite well in the time we have. Data, the
                  value and location is important then categorising again these could all be columns in that
                  asset register that you've created where it is what its value is and what category it falls
                  and definitely you need to categorise data and as we'll see later on when you create
                  classifications you're going to base it on the sensitivity or criticality of that data
                  and yeah if it hasn't already been categorised or if it hasn't been categorised properly
                  that could be quite a long task or quite a lot of work but it's worth doing to get that
        right otherwise you're not going to have full control over your data.</p>

                  <p>The other thing is there may be data that you don't even know about. The concept of
                  shadow I.T. has become more prevalent with the cloud so someone in your organization might
                  have just started or opened an account with dropbox for instance and started putting data
                  there or started using some cloud service that you don't know about and it's about getting
                  to the bottom of that knowing every person's role in their role what processes they do
                  where they store data, whether it's documented or not, and that in itself can take a while
                  but if you don't do it you don't have full control and you're just waiting for a breach
        to happen. So yeah that's what I've got on that thanks, David.</p>

                  <p>Yeah well, sometimes you're absolutely right sometimes people you know they don't
                  realise the implications of the data security and the risk because they're handling data
                  all the time and so for some reason, it may be a change of process, it may be a security
                  process, it may be an operations process but for some reason changing process or changing
                  flow means employees end up creating workarounds and they start trying to transfer media
                  from one place to another and you'll be amazed at all the different techniques and tricks
                  that they try you know breaking machines in order to ensure that they can copy data onto
                  a USB stick for instance. Which in most organizations you absolutely shouldn't be able to
                  do. They found someone that was able to unlock their particular USB ports and they brought
                  in you know their own USB stick so you know it's not even protected and suddenly lumps of
                  data are going onto this USB stick which obviously could be lost at some point and
                  transferred out of your location or out of the flow that it should have gone in and if
        it's too much information obviously that could be really damaging.</p>

                  <p>This has happened and employees have been caught and they've been sacked or reprimanded
                  and sacked. Some have been fined and then they turn around and sue the company for not
                  looking after them and the employee has won and so the company has lost you know both the
                  PR as well as on a security basis and now they got just horrible employee relations as
        well.</p>

                  <p>So you know the ramifications of just you know data categorisation managing that data
        knowing the flow in its location, huge!</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++15+Data+%3DValue%2BLocation+16+Categorise+Data.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
 
          <Footer />  
        
      </div>    
 
  )
}

export default Qsguide;
