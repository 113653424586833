import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #6 What is your data lifecycle?</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>So six, the data life cycle. So how much data is involved, where did it come from? This is normally something you discover, or you know made to be defined through the create stage. What happens to the data where you know and also what happens to the data that was employed in the process, you know how is it stored or archived? Also in its lifestyle are we certain it's encoded is it encoded at risk is it encoded in use is it encoded in transit how you know is it encrypted or encoded? How much data is altered or edited is all to do with the use of data. In some cases it can be just one field and then we have things like trace, source, application, destination of sensitive information across all processes and information systems so that comes under sharing in the data life cycle.</p>

                  <p>These are aspects we need to consider very closely because all of these you know where data comes from, how it's created, how it's stored, how it's used, and how it's shared are potential areas of risk and loss and even damage you know destruction. Obviously, it comes to a point though where we might want to deliberately delete okay or destroy data where we determine we don't need to keep it anymore because just keeping data around can be a risk. Especially if we're not going to use it. However, we may have to keep it you know, according to the legal reasons, or even compliance reasons, but we shouldn't have to keep it a moment longer than we actually need to keep it for. </p>

                  <p>So we need to be very careful that we really do destroy data and remnants of data so you often see certain places equipment being replaced and the old equipment being still stored albeit temporarily in a hallway. Someone could take that equipment so we need to be very careful about how we destroy or get rid of or clean equipment down. Many times, data, sensitive data has been discovered in a secondhand shop somewhere where someone has bought something for a fiver, turned it on and looked at the data, and discovered military secrets or medical information.</p>

                  <p>Is there any waste product? So likely it is, there's probably going to be media storing that so it needs to be clear or destroyed or burnt or chopped up, demagnetized but
                  destruction as increasingly difficult. We need to understand all the steps and we need to be very clear, what happens at each step? With every piece of data and every data
                    that we use whether and define the life cycle.</p>
                    {/*}  <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+6+Data+Lifecycle.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+6+Data+Lifecycle.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
