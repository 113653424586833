import React from 'react';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';
import Points from '../../Components/Points'

function Qsguide(props) {
  const {id, user, email,log } = props
  const showlog = log
  let pts2add = 1; const action = "/QSwebinars";
  Points(props, action, pts2add)

  return (
    <div className='mt-5 mb-5'>
      <Nav />
      <section className='flow'>
          <img className="article-main-image" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/chris-montgomery-small.jpg" alt='webinars' />
          <h1>Training</h1>
          <p className='lead ml-10'>Discovery through the actions of others</p>
          <div className='article-body flow ml-10'>

              <p>
                First of all, as much as we help others to learn, we are always learning ourselves. We are open to ideas and change. ESORMA was born out of the personal
                experience of David White and Mustafa Ahmed who came to similar conclusions from different directions. Both through
                industrial and commercial IT backgrounds that led to the delivery of a range of training programs from cybersecuity certifications such as CISSP to
                enterprise architecture certifications such as TOGAF and others.
              </p>

              <p>
                Two things are obviously missing from training that led to our initial consultancy work with PwC and others. First,
                what needs to be done in practice? Second how to communicate the benefits to the rest of an enterprise. A qualification is of obvious and excellent
                benefit to the individual, often leading to career progression or higher pay bands. However, the knowledge must be employed for the wider benefits to be enjoyed.
                Most often you are on your own and it is difficult to employ your knowledge, especially when colleagues continue to consider they are covered, simply because
                you have all the knowledge required. We know it is more than that, yet it takes some convincing.
              </p>

              <p>
                Nearly all our consultancy time has been employed in helping clients to either learn or implement. We worked with some major, large scale clients with whom
                we developed a domain led strategy which we wrote up. That strategy became the ESORMA Quickstart guide. We wanted to help more enterprises get started in
                this important area. We are not concerned if clients bought the book for implementation, worked with a third party for implementation, or worked with us. We
                figured if more people adopted the framework, the more we would learn and the better ESORMA would become. So far that has proved to be the case.
              </p>

              <p>
                We discovered we can be part of the team too. We can work alongside consultants and clients who pull in our expertise in at the required time. It helps
                that theirs is not the only voice as does our experience of implementation and of course, training and consulting technique. Which puts the client and the
                clients enterprise at the center of the conversation, providing coverage and protection through diversity and inclusion.
              </p>

              <h2>Please Support ESORMA</h2>

              <p>5-star positive reviews of the Quickstart guide on Amazon really helps the book to be found on Amazon
              as understandbly books recommended by readers are likely to be the better books.  <a href='https://www.amazon.com/review/create-review/?ie=UTF8&channel=glance-detail&asin=B08HGLPYC1' rel='noreferrer' target='_blank'>Please add your review.</a>
              </p>
              <p>
                Alos please show your support for ESORMA by following our LinkedIn page. Thank you. <a href='https://www.linkedin.com/company/65470643/' rel='noreferrer' target='_blank'>https://www.linkedin.com/company/65470643/</a>
              </p>

              <p>
                There are several more ways you can get involved with ESORMA. You can become a direct subscription supporting member. You can hire us directly for a specific
                consultancy project and you can invest in a specific training package.
              </p>

              <h2>Subscription Supporting Membership</h2>

              <p>Membership affords some privilege, you can have your say in how we progress. It would be a great opportunity to discuss your projects and we would be delighted to give you pointers and potentially find
              other members who share similar interests as you. You will be assured to get updates and news as ESORMA continues to develop
              and the occasional newsletter too. This is no hard sell!
              </p>
              <p>You will get a warm and fuzzy feeling for supporting a set of worthwhile ideas and you will help ultimately to protect us all. You can subscribe at any amount,
              although the minimum is $35 per month. <a href='#' rel='noreferrer' target='_blank'>Please subscribe here</a> This is tax deductable for most.
              </p>
              <p>
                This is a most welcome gesture designed to assist us to continue to find more ways to test
                ESORMA. We thank our existing patrons for their support and we welcome your support, thank you.
                Please let us know when you take out a subscription via <a href='https://www.linkedin.com/in/weboptimiser/' rel='noreferrer'
                target='_blank'>LinkedIn</a> or <a href='mailto:drclohite@gmail.com' rel='noreferrer' target='_blank'>Email.</a> Unfortunately we are so not focussed on the
                money it took us three months to notice our first subscriber, hence a quick message will help us get our act together in supporting you.
              </p>

              <h2>Hire Us</h2>

              <p>
                Both David and Mustafa are pretty busy and Mustafa is not available for the forseable future for any major projects as he has a few in hand. Never say never of course,
                things change. So let us know what you have in mind or need to get done. Most clients come to us who are already ESORMA supporters. There
                are supporters we trust to deliver ESORMA if we were unavailable at the time required. Let us know. David White has delivered keynote and closing presentations about the
                reasons why and benefits of adopting a synchronised, unified approach as suggested by the ESORMA plain english framework.
              </p>
              <p>
              Please let us know of your interests and we will provide a tailored quotation accordingly via <a href='https://www.linkedin.com/in/weboptimiser/' rel='noreferrer'
              target='_blank'>LinkedIn</a> or <a href='mailto:drclohite@gmail.com' rel='noreferrer' target='_blank'>Email.</a>
              </p>

              <h2>Virtual Training Packages</h2>

              <p>
                Following the last few years of experiences we will be releasing three more training packages in 2022.
              </p>
              <p>
                Many will be interested initially in the virtual training that
              supports the ESORMA Quickstart guide. This is a two day training event that may be extended to meet special needs. The minimum number of attendees is one. We cover four
              domains per day and we work through the questionnaires.
              </p>
              <p>The training is recommended if you are a trainer whether or not you plan to deliver the training to a bigger group yourself. It is important to make sure
              you are familiar with the scope and how we focus the training on the business needs while using ESORMA as the framework. You may realise after the initial training it
              would be a good idea for us to facilitate your larger groups. Indeed if you have a much larger set of groups or departments you may want our team to deliver this out
              across the board.
              </p>
              <p>We charge a fixed fee of £695 for the first person for two days (minimum) open training. Each additional same company person you invite to attend can enrol for 30%
              volume discount the 11th person to a maximum of 20 a 50% volume discount on the rate applies.
              </p>
              <p>
                Add 20% to the base rate for closed company training. In other words if you want training for your business and your business alone, add an addition 20% to the base
                rate before applying discounts etc. When you opt for closed training, we will take the time necessary to understand your specific business requirements and tailor
                delivery to suit.
              </p>
              <p>Please let us know of your interests and we will provide a tailored quotation accordingly via <a href='https://www.linkedin.com/in/weboptimiser/' rel='noreferrer'
              target='_blank'>LinkedIn</a> or <a href='mailto:drclohite@gmail.com' rel='noreferrer' target='_blank'>Email.</a>
              </p>


          </div>
       </section>

      <Footer />
    </div>
  )
}

export default Qsguide;
