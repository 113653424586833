import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #8: Staff As Assets & Data</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Some people have difficulty with this stuff as an asset I think the reason is it's not
        that we don't appreciate staff as an asset is that we might see staff as being out of scope.
        We might see staff as being something that is to do with HR not to do with security right
        and yet staff are employed to perform processes in operations and are absolutely critical
        to that process and a denial of service attack can actually be a member of staff is somehow
        limited or disabled in such a way they can't come into work that could actually stop
        operations in certain cases.</p>

                  <p>Staff assets from a securities perspective are a very worthwhile solid topic and you
                  need to work with HR to understand who the staff is and the departments you work with to
                  determine and create a RACI matrix chart for instance. We cover this in the book and also
                  supply a little bit of software, a spreadsheet in the portal to help you create the RACI
                  matrix chart to help you define where staff sit and it also helps you in the development
                  of the team to understand their experience and capability again it's something that's not
                  often looked at or is looked at only by HR and not necessarily understood by operations or
        security which can be can actually provide great insights to both operations and security.</p>

                  <p>This is an area again where you create opportunities for the business and in this case
                  for the people of the business with staff as assets so data, and staff. Mustafa touched on
                  the fact that they just should very much definitely be listed within your asset register
        and we deal with that and more detail within the book on page video.</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++13+Staff+As+Assets+14+Data.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
 
          <Footer />  
        
      </div>    
 
  )
}

export default Qsguide;
