import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #4: Evaluate. #1: Authority Structure</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Structure helps to ensure authority exists and in terms of authority I have Mustafa with us today to talk us through these slides as well and I
                      wonder if Mustafa you'd like to kick off with just a quick overview of how structure helps to ensure authority exists?</p>
                  <p>Hello yes, why not, so structure is always important as we know in any organization the word organization itself lends to some sort of structure
                      already existing but how do we ensure the structure helps information security? Well this is an important part of governance:  to make sure
                      senior management take information security seriously and then filter that down to the rest of the organization. It's also important for those in
                      management to understand what they are responsible for and they need to make certain decisions.</p>
                  <p>Chances are there will be the data owners of the organization unless they've assigned somebody else as a data owner so as data owners they will
                      have a responsibility for that data and also will decide who is allowed to access it and what type of access they should be allowed so
                      authority is required and necessary to support decisions so if they're in the right position they can make decisions</p>
                  <p>It's also the same in management if you give the right authority to an information security professional to be able to do their job. Then
                      everything will run smoothly. If they don't have the right authority to make those decisions they need to in the crisis or to respond to an
                      incident then all sorts of things can go wrong. There's a responsibility on both sides to understand that structure so the information security
                      professional needs to understand the structure of the organization and how it works and how operations work within the organization and vice versa.</p>
                  <p>Who's responsible for what and who's responsible for dealing with clients if there are any misunderstandings here then it can go wrong like I
                      mentioned so we have to make sure there's no misunderstanding. Everything's clear, the structure is clear, established and everyone understands it.</p>
                  <p>It's a good overview thank you. One of the key areas here is it's a collaborative approach so certainly you know you need to have the authority to be
                      able to demonstrate that the decision for security itself is an important area, but you also need to be able to communicate with other business
                      operation heads, staff who are responsible for key activities and the end client, whether that's you know in-house or or external. Everyone has these
                      different sets of needs and you know it might be too much actually for the chief or you know information security officer to actually know all the ins
                      and outs of every single part so the best thing that we can do is try to bring these people together in order to to develop a balanced process to
                      engage and encourage communication to avoid misunderstanding. So certainly having the authorities is absolutely required it needs to be there you
                      need to be backed by senior management but that helps you not only in making decisions but it helps you in opening duels and getting conversation
                      happening so that understanding can occur so that ultimately when you put something into place you're going to get signed off in agreement and
                      hopefully it'll go quickly. </p>
                  <p>I understand there's all sorts of complications with this, but because every business is different people are different but nevertheless having a
                      structure in place does allow people to you know follow along and realize and understand that this is a serious mission of the business to look after
                      its security. The default mode for most people is well you know you're the security officer you deal with that but in fact as a security officer our
                      key communication is to say no, security is responsibility of everybody. This is the part that you need to play because we need to make sure that your
                      area is as secure as you would like it to be as you tell us it is so let's just go through that and re-evaluate that process. Not everyone's going to
                      be willing so to have some structure there that is backed by authority can just help ease things along.
                      </p>
                    {/*}  <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/4+Enable/Enable+1+Structure.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/4+Enable/Enable+1+Structure_1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
