import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #2 Business Impact Analysis</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>This is where we look at the business of the enterprise and we're not just looking at the cost of a control, the cost of a given risk or, the cost of an assembly going wrong or process coming to a sticky end. It's the actual overall business impact we want to understand when we're not under threat, when we're not dealing with an actual attack, firefighting. What do we do? One of the things that we want to do is we want to undertake a business impact analysis where we kind of look at the bigger picture of what happens if that part or that process fails? How critical is it to the business? How could the business work around it if at all? There's other things, there's third party risk, there's suppliers, customers even, subcontractors even, employees have a risk. So we want to start to understand what those risks are that we face and what we would do as part of the BIA process is we would write impact statements we'd actually write them out.</p>

                  <p>The whole point of writing them out is we start to develop a structured way of looking at business processes from a risk perspective. We get a kind of a more rounded picture where we're not looking at necessarily just itemised costs but we're looking at itemised cause and effect so the overall business impact. That helps us to go to a higher level in our analysis of the effect of failure which obviously could be a result as an actual failure, it could be a deliberate failure, or it could be an attack or a threat and that last point calculate the knock-on cost, again, part of the impact is you know if that fails if that's under attack if it's compromised what effect does it have on that part of the business but also the rest of the business.</p>

                  <p>I can only add that it does flow from the last domain. We looked at priority, we looked at risk, we looked at creating a triage of risk as well but sometimes you might find risks and it might even seem serious. How serious is it? What impact will it have on the organisation? Until you work that out you don't know whether you need to put a control in place or whether the control, the controls you already have are adequate you might not need to do anything, or you might realise that the impact is so great of a risk that you never even thought about it in that way and you don't have a control in place and you need to put one in place as soon as possible. So analysing the impact of risk not just knowing what the risks are is a critical part of cybersecurity and information security.</p>
                  {/*}    <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+2+BIA.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+2+BIA.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
