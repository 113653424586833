import React from 'react';
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="compliance: audit work" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/compliance-auditor-82JHLUM+small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #8: Compliance</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <h4>Overview</h4>
                  <p>Number of Video and Audio files to be determined </p>
                  <p>Average viewing time: to be determined</p>
                  <p>Recorded: Yes</p>
                  <h4>Domain #8: Comply</h4>
                  <i>"Automate more and toil less"</i>
                  <ol>
                      <li>watch this space for when the files are uploaded and linked</li>
                  </ol>
                  <p>Talking points selected, webinar TBA soon.</p>
              </div>
          </article>
          <Footer />
      </div>
  )
}

export default Qsguide;
