import React from 'react';
import ReactPlayer from 'react-player';
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #3 Recovery Time Objectives (RTOs)</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Another three-letter acronym: Recovery Time Objectives related to business functions so RTOs.</p>

                  <p>The recovery time objectives it's about when there's a failure we have, we can, we ought to be already knowing the amount of time we have to recover. Correct yeah. The time that we have to recover is known as an RTO so recovery time objective so what we want to do the recovery time objective is an hour we want to be able to make sure we have systems in place or solutions in place that can enable us to recover within an hour.</p>

                  <p>Every process that you employ will have a different RTO so for instance if it's live financial data you might be your RTO might actually be milliseconds or zero. You can't afford to lose a transaction. On the other hand, if you're producing tons of coal or some raw product then you might actually have tons of raw products stacked outside or in a warehouse and that stack has to wear down or go down before it becomes a problem. So you don't mind although you prefer not to working down your stock that gives you time, okay so these are solutions to extending time which you can only understand or start to put into place when you've calculated your RTO your timing.</p>

                  <p>Calculating RTOs in a business environment is critical. You can relate it to your business impact analysis, you can relate it to your threat analysis, you can use RTOs in so many different ways.</p>

                  <p>But yeah and there's the page number shown there.</p>

                  <p>It's about making sure you can recover functions and why do we need RTOs? What is the problem if we don't meet the recovery time objectives for a function? Yes, your business might suffer in a way that you can't recover. Before it gets to that stage, that's what you want to do with your recovery. You want to recover before it gets to a stage where it becomes irrecoverable. Yeah, absolutely RTOs are there to avoid getting to a critical time so that you can design in recovery because you've already determined what the objectives are. You already know how long you have to live, or the business has to live, or that process has to live before it becomes critical. If and when it does become critical then you know a given production line may then be perceived as having failed and then it depends on that level of production line or operations as to the actual overall criticality. Again, you know, it's about preparation if we calculate the RTO ahead of time it means that we can then prepare a solution or have it on standby and be certain that if we were to rely on that solution or that safeguard to kick in that it could kick in within the RTO. That will help us define, yes. That'll help us define actually what is a good solution for a given problem that we want to solve or critical need business function that we want to avoid going critical. It helps us in the selection criteria or evaluation of the safeguard that we ultimately decide upon.</p>

                  <p>People might be wondering why we're talking about production halting or operations coming to a standstill and not being able to cover production line for instance there's so many different types of businesses out there but one thing you'll find in common is they all use technology and when we have a breach or an attack on us cyber systems or information systems it's not just your data that's lost, the whole business can come to a standstill. That's why these are used heavily and they all feed all your RTOs for all your business functions, feed into your business continuity plan which is something we do talk about in more detail in other domains but, these are all gonna feed into your business continuity plan if you know how long you have to recover that's what's going to be in your plan. It's a very important part and it's something that has to be reviewed very often as well because your business is changing everyday threat levels are changing keeping up to date with your RTOS and keeping them fresh is part of your ongoing and continuous cybersecurity.</p>
                  {/*}    <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+3+RTOs.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+3+RTOs.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
