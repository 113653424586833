import React from 'react';
import ReactPlayer from 'react-player'
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #4: Every Aspect, Document Assets</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>
                      Scope applies to just about every aspect of enterprise security, it doesn't fit in
        isolation. Scope is something you do before a project, during a project or during
        implementation, and after implementation, because of course, we can implement solutions
        or controls.
                      </p>

                  <p>
                      As we all know a control can bring in extra or new risks.
                      </p>

                  <p>
                      So it's really important that we document what we're doing and in particular, what we
                  do is we document assets. When we talk about assets we refer to them as being software,
                  hardware, people, infrastructure, kind of everything, data that we can describe is in one
                  way or another. If it's required for the business it should be considered as an asset and
        it should be documented.
                      </p>

                  <p>
                      I know some people go oh my goodness no, what a lot of the work that is, well yeah it
                  is, but, you know what if this information is not actually documented somewhere then how
                  can it be managed and controlled? The chances are is a lot of it, most of it, if not all
                  of it, one way or another is already documented so a lot of what's required is pulling it
        all together. Okay and we'll get into more detail into these on some of the other points...
                      </p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                          url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++5+Apply+Every+Ascpect+of+Scope++6++Document+Assets.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
          <Footer />  

        
      </div>    
 
  )
}

export default Qsguide;
