import React from 'react';
import { Link } from "react-router-dom";
import Points from '../Components/Points'

//import Points from '../Components/Points'
//import { MDBCard, MDBCardTitle, MDBRow,MDBCol, MDBCardImage, MDBCardBody } from "mdbreact";


function WelcomeNews1(props) {
  const {id, user, appemail, log } = props
  if (log === 6) console.log('QSresources',props,id, user, appemail, log)
  const showlog = log
  let pts2add = 1; const action = "/holdingPage";
  //Points(props, action, pts2add)

  return (
    <div className='m-5'>
    <p>
      Welcome to the ESORMA members portal
    </p>
      <header className="App-header">
        <img src='https://esorma.com/images/ESORMA.svg' className="App-logo" alt="spinning ESORMA logo" />
        <br/>
        <p>
          Welcome to the ESORMA members portal
        </p>
        <p>

        <Link to='/QSResources'
          className="App-link"
        >
        ESORMA Free Resources
        </Link>
        </p>
        <p>
        <br/><br/>
        <a className="App-link" target='_blank' rel="noopener noreferrer" href='https://amzn.to/39LV4t7'>Please review the Quickstart Guide on Amazon</a> <br/>(your review helps to spread the word)
        </p>

        <p>
        <br/><br/>  
        <Link to='/getintouch'
          className="App-link"
        >
        Get In Touch & Attend Our Next Virtual Meeting
        </Link>
        </p>
      </header>
    </div>
  )
}

export default WelcomeNews1;
