import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #4 Employment Agreements, Onboarding & Offboarding</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Employee agreements, onboarding, and off-boarding. A quick list to run through of employee
                     agreements, non-disclosure agreements, etc., and you know if you've just taken on a role in a
                     new organisation you really obviously want to determine if these policies are in place or if
                     they're needed and is there an onboarding and an off-boarding process? The onboarding we
                     touched on earlier with the: 'we're setting a precedent' to a new employee, we're telling
                    them security happens here, we're concerned about security, at an individual level great.</p>

                  <p>But off-boarding, off-boarding is often accidentally forgotten about in some cases so I
                  know of a major employer who is a local government with many departments although 50% of its
                  budget is spent on childcare it's dare I say, these are political places, so there are
                  agreements and disagreements processes and every now and again they blow up. People go a bit
                  crazy and tempers are frayed and there have been situations where a board may fire the CEO or
                  the CEO may fire an adviser, out of temper or spite or who knows but it happens and when it
                  happens all too often because of the circumstances usually being angry or disgruntled is the
                  polite term it might just not be reported, it might not be communicated, it might be that the
                  person hopes to smooth things out later, and so although the shouting goes on and someone is
                  fired, HR or people who run the payroll are never told so we can end up, and we do end up with,
                  millions being wasted on salaries to employees that aren't coming to work anymore usually at
                  the higher levels they're the people who get paid the most, so hence it can add up to millions
                  quite easily and these are often people who have access to information, obviously they have
                  money, they may still have resources like phones and cars and computers etc., so these are
                  assets that are being wasted, not collected in, so off-boarding, the process of off-boarding
                  is absolutely key and needs to happen more often at a senior level certainly. Obviously at
                    lower levels too at every level it needs to happen.</p>

                  <p>Ideally, what we want to do if we want to terminate or remove connections to office systems
                  and data before we announce the person that they are being fired if that's what we're doing
                  with them, or laid off, because obviously however sensitively you may terminate someone's in
                  employment they can quickly go to a place of misunderstanding and unhappiness and want to get
                  their own back and when they get their own back they can be really quite vicious because they
                  know about your organisation, they know where the bodies are, they know where, how to get
                  access to the data, they know how to hurt you the most, so you really need to cut them off,
                  disconnect them really while they're walking towards the meeting room where they're about to
                  find out. You don't really want to cut everything else off and then they come to the
                  termination meeting, and you certainly don't want to leave it till afterwards. Timing needs
                    to be absolutely key here any quick thoughts on that Mustafa?</p>

                  <p>Yeah i'll just add, the reason why they're talking about this kind of thing from the
                  priority domain even though some people might find this basic or common sense it should be
                  done, but the shocking fact is it's not done a lot of times and that's why we keep hearing
                  about the breaches and issues especially when you have clouds these days the problem of
                  shadow IT, you might cut them off from some accounts but they may have some cloud services
                  where they've got data or access or architecture. The IT department might not even be even
                  aware it of so they can't withdraw their access, so there's a lot more to onboarding and
                    off-boarding these days but it has to be looked at again and it has to be addressed.</p>

                  <p>Yeah it does happen it's just one council, and there are hundreds of councils but one
                  reasonably large council, can add up to millions and it's it is just looking at the councils
                  in Britain adds up to hundreds of millions just wasted money and massive risk and that's
                  just the council example so what about commercial scenarios again it's got to be in tens
                    of millions that this is happening.</p>
                      {/* <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-7-8+Final.mp4'
                          width='22rem'
                          controls={true}
                  /? */}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-7-8-1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
