import React from 'react';
import ReactPlayer from 'react-player';
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="enable: showing another what to do" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Enable.jpg"  className='article-main-image' />
              <h1>Domain #4: Enable</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <h4>Overview</h4>
                  <p>9 Videos. 9 Audio files. </p>
                  <p>Average viewing time: to be determined</p>
                  <p>Recorded: November 17th 2020</p>
                  <h4>Domain #4: Enable</h4>
                  <i>"A business and it's team only deliver when they are enabled"</i>
                  <ol>
                      <li><a href='/MM4Authority'>Authority Structure</a></li>
                      <li>watch this space for the full 9 files</li>
                  </ol>

                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/4+Enable/Enable+Webinar.mp4'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>     
          <Footer />
      </div>
  )
}

export default Qsguide;
