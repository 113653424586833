import React from 'react';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';
import Points from '../../Components/Points'

function Qsguide(props) {
  const {id, user, email,log } = props
  const showlog = log
  let pts2add = 1; const action = "/QSwebinars";
  Points(props, action, pts2add)

  return (
    <div className='mt-5 mb-5'>
      <Nav />
      <section className='flow'>
          <img className="article-main-image" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/chris-montgomery-small.jpg" alt='webinars' />
          <h1 >Get In Touch</h1>
          <p className='lead ml-10'>It is always good to talk</p>
          <div className='article-body flow ml-10'>

              <p>
                First of all, as much as we help others to learn, we are always learning ourselves. We are open to ideas and change.
              </p>

              <p>
                We try to get things right and we have had a lot of positive feedback which is gratifying, but we don't let it go to our heads. There is still change.
              </p>

              <p>
                Like all good CISOs, just when we think we have all the bases covered, something new comes along!
              </p>

              <p>
                So please get in touch, ask us questions, paint a picture, or if you have a difficult scenario, let us know and we will respond either by message, email or by zoom call.
                Message us via <a href='https://www.linkedin.com/in/weboptimiser/' rel='noreferrer' target='_blank'>LinkedIn</a> or <a href='mailto:drclohite@gmail.com' rel='noreferrer' target='_blank'>Email.</a>
              </p>

              <p>
                Or, join us at our next live and in person event. We are all set for next Thursday at 8pm GMT for about half an hour. <a href='https://us02web.zoom.us/meeting/register/tZAvfu6rqTorHtTivf7DN27zAnkl0rygBLD_' rel='noreferrer' target='_blank'>
                Join us by using this link</a>
              </p>

              

          </div>
       </section>

      <Footer />
    </div>
  )
}

export default Qsguide;
