import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE. Always start with Scope</h1>
              <p className='lead'>CISO implementation support</p>

              <div className='article-body flow'>
                  <p>
                      Always start with Scope.
                   </p>
                  <p>You probably appreciate always being stretched slows progress. You may agree a good plan will give you the clarity of focus you need.</p>

                  <p>This is why we wrote Domain #1: Scope in the ESORMA Quick Start Guide, to give more clues about how to determine where to start and to draw up a plan.</p>

                  <h4>Video Information</h4>
                  <p>10 Videos, 20 key points</p>
                  <p>Average viewing time: 3 minutes 17 seconds</p>
                  <p>Recorded: September 22nd 2020</p>
                  <h4>Domain #1: SCOPE Checklist</h4>
                  <ol><li><a href='/MMWhat'>Always start with Scope</a></li>
                      <li><a href='/MMWhat'>Security + Governance</a></li>
                      <li><a href='/MMWhat'>What is Scope?</a></li>
                      <li><a href='/MMMuscle'>Muscle Memory</a></li>
                      <li><a href='/MMAspect'>Every Aspect Of Scope</a></li>
                      <li><a href='/MMAspect'>Document Assets</a></li>
                      <li><a href='/MMAsset'>Asset Protection</a></li>
                      <li><a href='/MMAsset'>Department Asset Registers</a></li>
                      <li><a href='/MMComm'>Commission Asset Registers</a></li>
                      <li><a href='/MMComm'>Are Assets in Registers?</a></li>
                      <li><a href='/MMALoc'>Asset Locations</a></li>
                      <li><a href='/MMALoc'>Asset Values</a></li>
                      <li><a href='/MMStaff'>Staff As Assets</a></li>
                      <li><a href='/MMStaff'>Data</a></li>
                      <li><a href='/MMData'>Data = Value + Location</a></li>
                      <li><a href='/MMData'>Categorise Data</a></li>
                      <li><a href='/MMClassify'>Classify Data</a></li>
                      <li><a href='/MMClassify'>Asset Ownership</a></li>
                      <li><a href='/MMTools'>Use Tools</a></li>
                      <li><a href='/MMTools'>Geo Mapping</a></li>
                  </ol>
                  <h2>Full webinar</h2>
                  

                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++Scope+Final.mp4'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />      
      </div>    
  )
}

export default Qsguide;
