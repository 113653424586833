import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #2 Methods and Human Factors</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>It comes down to methods usually in terms of quantifying of risk and the two methods of the
        quantitative risk which is generally numeric the key is the 'n' in the word quantitative or qualitative.
        There's a lot of people who don't really like the concept of qualitative because qualitative assessments
        are kind of best guesses however, qualitative assessments are fast you can do them on the fly, they may
        well be rough and ready, they may well be inaccurate but when you're under pressure you have to make an
        assessment based on something and sometimes that's all we have.</p>

                  <p>However, if you have pre-prepared and time is has been on your side then obviously you should do what
                  you can to get a quantitative analysis. Quantitative analysis is a numerical analysis so you may look at
                  things like cost of repair, cost of replacement, cost of solving a problem, cost of the solution, cost of
                  the control, and just have more time, the issue with costs and why it needs more time is very often you
                  will need to go and find from a supplier or a third party, what the actual price or cost is likely to be.
                  Costs are not just around the cost of equipment or systems they can include the cost of installation, of
                  setup, of maintenance, and ongoing support. A lot of costs are hidden. Sometimes costs go up over time,
                  sometimes they go down over time so it just varies across everything so property, for instance, tends to
                  go up in value, IT equipment tends to go down in value, it just depends on what is considered a commodity
                  and what isn't and a whole set of pricing rules and this information should ultimately be recorded
                  somewhere so you should be able to take hold of some information it's got the pricing information that
                  you need but if that's just not available to you then you have to use qualitative systems there's more
        of that in the book but that's basically the two methods. Mustafa?</p>

                  <p>Yeah see usually you want to use a combination like you said. If you've got the time to do it and
                  explaining that your architecture you want to do a bit of both and we've seen a number of times and over
                  the span of your career you've probably seen different people prioritise different things of what's
                  valuable to one person, it's not the same to another, so it's quite subjective the qualitative approach.
                  Whereas the quantitative approach, I mean you can't argue I mean that's how much it's going to cost at
                  the end if you're doing the calculation you want the cold hard numbers you do that but if you do
                  qualitative before you do a quantitative analysis, if you have an idea of the damage that could be done
                  because of a certain risk or you gather information from different courses and then you do a more deep
        analysis. iIeally you want to use both but like you said you don't always have opportunities.</p>

                  <p>Number four human factors: consider human factors that affect risk and a lot of people tend to blame
                  human factors but I would argue that most human factors are actual are actually, or risks associated
                  with humans normally about lack of process or even incorrect process. So, remember in a kind of hierarchy
                  of things when it comes to protecting our business the number one thing that we're protecting is what...
                  human life, each other, so you know if there was a chain, yes of course the business will always say
                  the business and people have different interests but actually our objective number one objective is to
                  look after our fellow humans and to make sure that the processes and security practices and the business
        takes care of not just health and safety but the actual processes that they employ.</p>

                  <p>Because what tends to happen is if we over legislate we put in too many fixed processes there's a
                  chance that we can create processes that stop people from doing the work that they need to do and what
                  do they do Mustafa? and they can't do the work? They work around yeah, they will find their way around
                  it and possibly, well the chances are high, they will break any security practices in preference because
                  they just need to get the job done. This has caused lots of problems and certain times companies have
                  blamed people even fined people, sacked people, and then those people have then made a counter claim
                  back on the company and the company has lost, well the enterprise has lost and so that's meant not only
                  is the enterprise got a much worse PR front to deal with they've also got terrible worker relations and
                  increased costs and still potentially not even a solution because they're playing the blame game and not
        actually dealing with risk.</p>
                      {/*<ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-3-4-+Final.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-3-4-1.mp3'
                      width='22rem'
                      controls={true}
                  />

              </div>
          </article>

          <Footer />

      </div>

  )
}

export default Qsguide;
