import React from 'react';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';
import Points from '../../Components/Points'

function Qsresources(props) {
  const {id, user, appemail, log } = props
  if (log === 7) console.log('QSresources',props,id, user, appemail, log)
  const showlog = log
  let pts2add = 1; const action = "/QSresources";
  //Points(props, action, pts2add)

  return (
      <div className='mt-5 mb-5'>
          <Nav />
          <article className='flow'>
              <img className="article-main-image" alt='cloud storage' src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/cloud-storage-118x210-01.jpg" />
                <h1 className='frontpage'>ESORMA Quick Start Guide Resources</h1>
                <p className='lead'>Tools, Plans, Case Studies, Workshops, Webinars and Masterminds all here to support the CISO community</p>
              <div className='article-body flow'>
                  <p>ESORMA at a glance</p>
                  <p>
                      <img className="img-fluid resourceStar" src='https://s3.eu-west-2.amazonaws.com/esorma.com/images/Scope-Map.png' alt='ESORMA Scope Map' />
                  </p>
                  <div>
                      <h2>Domain 1: Scope</h2>
                      <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/Information_Security_Risks_Assessment_A_Case_Study.pdf'>Risk Assessment Case Study</a></h3>
                  </div>
                  <div>
                      <h2>Domain 2: Prioritise</h2>
          <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/Risk+Assessment+Calculator.xls'>Risk Assessment Calculator</a></h3>
          <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/Risk+Management+CASE+STUDIES+May+2016+04.pdf'>Risk Management Case Study</a></h3>
          </div>
          <h2>Domain 3: Evaluate</h2>
          <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/BIA+Employee+Questionnaire.xls'>BIA Employee Questionnaire</a></h3>
          <hr/>
          <h2>Domain 5: Harden</h2>
          <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/Basic+BCM-DRP+Stepped+Plan.pdf' alt='BCP Stepped Plan Download'>BCP Stepped Plan</a></h3>
          <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/4+part+BC+DRP+Plan.pdf' alt='4 part BC/DR Plan Download'>4 part BC/DR Plan</a></h3>
          <h2>Domain 7: Harden</h2>
          <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/NIST+Case+Study.pdf' alt='NIST Case Study Download'>NIST Case Study</a></h3>
              </div>
          </article>
          <webinar className='flow'>
              <img className="article-main-image limit" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/chris-montgomery-small.jpg" alt='active webinar engagement' />
              <h1 className="frontpage">Chapter Discussion</h1>
              <p className='lead'>Chapter by chapter author discussion</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/QSwebinars'>8 Domains, hour long sessions with 4 minute excerpts.</a>
                  </p>
                  <p>
                      Video, Audio & transcripts
                  </p>
              </div>
          </webinar>
          <webinar className='flow'>
              <img className="article-main-image limit"  src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/taking-118X210-01.jpg" alt='one to one workshop' />
              <h1 className="frontpage">DIY Workshop Guides</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/QSguide'>8 Domain Questionnaires</a>
                  </p>
                  <p>
                      Ready to download PDFs
                  </p>
              </div>
          </webinar>

          <webinar className='flow'>
              <img className="article-main-image limit"  src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" alt='telescope' />
              <h1 className="frontpage">mastermind Interviews</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/QSmastermind'>5 interviews shed light and provide some ideas on solving classic industry issues</a>
                  </p>
                  <p>
                      Audios and Videos with transcripts
                  </p>
              </div>
          </webinar>



          <Footer />
      </div>
  )
}

export default Qsresources;
