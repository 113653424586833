import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #5 Review Threats, Sources & Vulnerabilities</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Review threats and sources and they should be familiar to you. Every
                    organisation tends to get a certain type of threat, they come from a certain type of place, now
                    you know if you're a big email user, you know there's like potentially phishing threats, and
                    various sources, which you know you can get round to whitelisting and you know closing down
                    certain systems from external influence and we move from there into specific vulnerabilities,
                    again it's all related to the kind of business that you're in, but the tighter you can get
                    into these, the closer you can narrow down to the type of typical threats you might
                    face and typically the type of sources. It just means you can start to think about how
                    you can protect from those similarly specific vulnerabilities.</p>

                  <p>But it might not be cyber so give a council example I mean it can be any. It could be a
                  newspaper type company, it could be an R&D well especially an R&D type company which has got
                  multiple sites within a region, so you know the chances are the security at each site is
                  different, especially if you know their buildings are of different ages, especially if some
                  buildings are owned, some buildings are rented. I mean it's been heard of many times, people
                  turning up with carpets and tape measures and overalls measuring up for carpets for which the
                  employees look around and go about time. They let these people in to measure up for carpets
                  and about two hours later they start realising that purses are missing, personal devices are
                  missing and in fact, this has all been a complete fabrication. There are no carpets but there
                  was no one checking that anyone was planning to put any carpets in and they just let them in
                    at a remote site and then all this theft occurred it happens. Mustafa...</p>

                  <p>Yeah the only thing I can add to that is yeah again it's basically that any organization
                  should be doing but you're right you have to understand the organization that's where
                  sometimes we fall down we don't understand everything about the organization who do you
                  learn this from? Generally when you're going to an organization as your information skills
                  manager and your job is to find out what sort of data is held in which departments and
                  what the organization do you generally interview the managers of those departments and then
                  you find out about what data you have and you plan around that but I'd say you need to go
                  further than that because there may be things that even the managers don't know so you have
                  to go to employees and maybe randomly select a large organisation, can't go to every single
                  one, but you want to see things like what we mentioned before, problems people may be
                  having that no one's solving or won't look like, or didn't look like a priority at the
                  time so people just find different ways of doing things which breach policies but they
                  they're doing it because they need to get their job done. You can find the issues and
                  that example I gave was just setting up some cloud accounts that sort of send an email
                  if they're corporate your email system wasn't working just so they could get their job
                  or storing data because they run out of data and the technical support hasn't given them
                  more storage, when they ask for it so they just set up a dropbox account put sensitive
                    data up.</p>

                  <p>These sort of things happen and you can only find out when you you ask people about
                  the way they work what they do what issues they have and that's where you're going to get
                  down to these specific vulnerabilities that are maybe creating a larger. Didn't we just
                  had the Covid reporting and the 16-bit limitation in excel file... exactly, a discussion
                    for another webinar we could talk for days on that but yeah it happens all the time.</p>
                    {/*}  <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-9-10+Final.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-9-10-1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
