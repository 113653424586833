import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #5: Asset Protection and Registers</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Asset protection: normally this is a calculation this is something that you would work
        out and it usually is based around something you know like affordability. It may be value
        of risk you know what are the once we know what the assets are we've started to list them
        if we have list if they exist in asset registers already which they should do in most
        organizations then we might be able to get a pretty clear idea as to you know a priority or
        a listing according to value and from there we can say well clearly the most important
        thing we need to protect is whatever the conclusion of that documentation is and then
        when it comes to how much money do we have to spend the answer is normally not more than
        the cost of the asset, normally.</p>

                  <p>So, as a security professional that'll be something you would work out but normally
                  they are the basis of calculating and determining what it is and how we might start
                  thinking about protecting the assets that we've got but I covered it earlier with asset
                  registers are often existing within a department so you and if they already exist
                  obviously it's going to save a lot of time that doesn't mean they're up-to-date or
                  accurate or even complete they will probably not have a lot of information that a
                  security professional would want to see. You may or may not know what the equipment is
                  for what application is served who bought it how much it was might not be a clear serial
                  number or any of that kind of detail or it might be all serial numbers I've seen after
                  this like that which is just unbearable you can okay you've got to like translate it
                  into English but these are tasks that we need to do because we just need to take
        advantage of asset registers we're going to get into a bit more about those.</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++7-8+Asset+Protection+%26+Departmental+Asset+Registers.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
 
          <Footer />  
        
      </div>    
 
  )
}

export default Qsguide;
