import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #11 Tools & Geo Mapping</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Use tools! There are many tools you can use. I often liken spreadsheets to being like a game of Twister
        it's a bit crazy you can get a bit out of hand. There are dedicated software you can get again that can be
        problematic but also useful. It just depends on, you know, you've got to be careful when you select the tools
        you use tools that can really do the job so you know, spreadsheets are particularly useful for smaller
        organisations or smaller departments. You can have multiple sheets in spreadsheets.</p>

                  <p>A slight problem is that spreadsheets tend to be built and created on a single laptop or machine somewhere
                  so if something happens with that machine, the whole lot is lost there are various issues with excel and the
                  programmable nature of it as well. It's not known to be the best so you definitely need to ensure you have
                  really good backups of spreadsheet data that would be my number one piece of advice for using tools I mean
        the number one piece of advice would be to use tools and if nothing else by all means use excel.</p>

                  <p>Excel will help but you do definitely need to manage your various backup versions because you could do a
                  lot of work and end up with nothing. As I said it can be productive and we've seen people have that kind of
        difficulty so there's the caviat.</p>

                  <p>Geo-mapping is possible. There are tools that can track data and tell you where it is you know IP numbers
                  of servers often map to physical locations, not always because obviously they can be altered, but in many
        cases, they can rapidly provide you with location data and that can be very useful. Mustafa?</p>

                  <p>I would say use as many tools that make your life easier as possible and we do provide certain tools on
                  the portal to make it easier, templates, and tools rather than reinventing the wheel. Chances are someone's
                  already done this why not leverage what's already out there if it's something you can find on this on my
                  website in the tools section use that and there are a lot more software tools templates out there if you
        know how to look for them at the end of the day you want to make your life easier.</p>

                  <p>Automate what you can if possible use scripts to gather data. The easier it is, the more chance there is
                  that it's going to be completed and the data is going to always be in scope and it's so it's easier to
        manage risk. If you start making too many tasks manual things are gonna get left behind.</p>

                  <p>Yeah, I'm thinking what I should do in fact on the portal is I should make a tool sharing section
                  shouldn't I where we provide our tools that we come up with and found or modified edited and created
                  whatever but then we make it so that members can also upload tools that they found useful so it becomes
        a kind of tool exchange.</p>

                  <p>There's plenty of open-source tools available as well or creative commons license tools that people are
                  free to use so again why you reinvent the wheel when there's already something out there that you can use
        okay I'm going to find the portal in that area.</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++19-20+Tools+and+Geo+Mapping.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
          <Footer />  

        
      </div>    
 
  )
}

export default Qsguide;
