import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #3: Muscle Memory.</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Point three here: What's in scope? Muscle memory.  Yes, definitely I mean once you've been given the task and it usually is quite a large task, say you're in a multinational organization and you have to understand you're responsible for all the security of all the information that the organization has.</p>

                  <p>Now, what type of data do they store where is it? Which parts of the organization are already protected? Which ones are not? Where do you start? You have to start looking at what am I doing right now? And what is within Scope of the task at hand?</p>

                  <p>If it's a matter of dealing with a breach that's already happened the focus is going to be different. If it's something where you've got more time you're developing a security program you're implementing security projects so just reviewing security in general you may be able to set a wider Scope and do a bit more work to then focus on the data that needs to be protected.</p>

                  <p>The first thing you have to do is find out where your focus is going to be and if you start doing this on a regular basis it does become 'muscle memory' so it should be something that becomes second nature always looking for what you need to focus on rather than just looking at a big massive task, a mountain that you need to climb. </p>

                  <p>Breaking it down into smaller hills is how I sometimes describe it so whether you're doing architectural scoping, requirement sculpting, solutions scoping whether you're scoping cyber security or just data scoping. The process is the same it's about focusing on what needs to be in our practice basically. What we're gonna actually work on right now? What do we need to eliminate and stop from distracting us? If we can do that and if that becomes second nature we will become a bit more efficient at dealing with the cyber security issues.</p>

                  <p>There will be less mistakes made. it's usually better to do this from a high level if you have the opportunity so yeah, that's muscle memory. The more you do it the better you get at it and it's a good habit to have always focusing on what is in scope and what's out of scope. Then can just take a decision, so yeah okay that's all from me anything else to add?</p>

                  <p>Yeah, you know one thing about scope is the big dangerous 'scope creep' so part of defining Scope and doing it early is to narrow the focus. Therefore the more you could we're going to come to documenting scope as well in a minute but the more you actually narrow the focus you define Scope and you're clear on what the scope is then the more likely you're going to finish the project or get the job done, satisfactorily.</p>

                  <p>Also by defining Scope and being absolutely clear about what's in scope it helps avoid or reduce scope creep and if the scope does expand then people, the management perhaps potentially, or partners in an enterprise will have a mechanism by which they can see where or how Scope was increased, and this can give you access to more time to complete your task or they'll say if more budget or other resources. You know maybe if you increase scope if you want to do something more, great but what are you going to give me to enable me to do that?</p>

                  <p>So Scope is actually really about managing your own control yeah the muscle memory, it has to be there you have to follow this process but it's become automated in your own head.</p>
                  <p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                          url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++3-4+Scope+%26+Muscle+Memory.mp4'
                          width='22rem'
                          controls={true}
                      />
                </p>
              </div>
          </article>
 
          <Footer />  
        
      </div>    
 
  )
}

export default Qsguide;
