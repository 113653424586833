import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #6: Commission Asset Registers</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Mustafa, would you like to talk about commissioning asset registers?</p>
                  <p>Yes, definitely I mean if there's usually you will find some sort of listing of assets
                  as IT registers but generally, data sets are not included on most asset registers that
                  may have covered hardware and software whatever the organisation owns or has to protect
                  and when you're talking about data assets every data set we have needs to be documented
                  and as data is always changing in an organization these registers do get out of date
        really quickly. Ensuring that you know what you have to protect.</p>

                  <p>The only way you can do that is find out what it is and put it in the register and these
                  are the things that you're going to list next to each item that you put in that register
                  as we'll be looking at throughout this presentation but the first thing is what the asset
                  is and where it is etc so if there isn't one, you're going to have to create one, not a
                  difficult thing to do, tools are available as required you could even use a spreadsheet
                  just to list everything initially, or you could use more than one tool oh yes definitely
                  that type of tool is available we do have some tools already that you can use and one of
                  them is an asset register and it's also about making sure everything is included like I
                  said it could be out of date or if you've taken registers from other parts of your
                  organisation making sure that they are comprehensive to cover everything that doesn't
                  need to be protected are they in the register it's as simple as that these steps might
                  seem simple and straightforward but when you're talking about a lot of data and data
                  that's distributed all over the place outside your organization in data centers in the
                  cloud it can become quite a task gathering all the knowledge about the data before you
        can do anything about protecting it.</p>

                  <p>Yeah we're going to talk about some different ways of using that data or visualising
                  that data managing that data as we within these 24 points so spending a bit of time on
        your asset registers bringing them up to date making them honest really really vital.</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++9-10+Commission+AR+Are+Assets+in+AR.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
 
          <Footer />  
        
      </div>    
 
  )
}

export default Qsguide;
