import React from 'react';
import ReactPlayer from 'react-player';
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="Harden: man wearing hard hat" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/protect+portrait+small.jpg"  className='article-main-image' />
              <h1>Domain #5: Harden</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <h4>Overview</h4>
                  <p>Number of Video and Audio files to be determined </p>
                  <p>Average viewing time: to be determined</p>
                  <p>Recorded: January 20th 2021</p>
                  <h4>Domain #5: Harden</h4>
                  <i>"Only resilient organisations survive"</i>
                  <ol>
                      <li>watch this space for when more files are uploaded and linked</li>
                  </ol>
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/5+Harden/Domain+5+Hardening+Webinar.mp4'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>     
          <Footer />
      </div>
  )
}

export default Qsguide;
