import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #7 Documenting Risks & Business Impact Analysis</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                   <p>Yes, we did touch on this in the Scope domain as well but we mention it here because
                  unless you're documenting and you're reviewing the documentation such as your risk register
                  and your asset register on a constant basis these documents will get out of date and because
                  we rely on these to make decisions on how to do what, because we need to put in how to secure
                  the organization. It's incredibly important that we keep these up to date and also keep them
                  safe. Again it seems obvious and common sense but the amount of sensitive data you have in
                  these registers means it shouldn't be accessible by anyone and you have to also look at the
                  proximity of how can the risk occur what type of risk occurs and if that's changing, you need
                  to update the registers as well as just to maintain control over the version what you don't
                  want changing which one is the latest most up-to-date document there's a lot of compliance
                    reasons you might need to document a lot of this as well.</p>

                  <p>It's not just the risk you're documenting, what controls you put in place, and how you're
                  implementing control for instance. The ICO is able to audit an organisation to check whether
                  you're implementing security for data appropriately they'll want to look at these documents
                  because it's not just for yourself it's also for the auditors and you also want to make sure
                    that you're always protected and you've got continuous protection.</p>

                  <p>14 business impact analysis. You know about the risk, you've documented the risk, a column
                  that's essential in that risk register is that business impact so you can, like you mentioned
                  before, so you can see which of the risks you do need to prioritise. To do that you have to
                  understand the organisation I keep going on about it but it's often not the case that people
                  that are looking at technical security especially, are focused so much on the technical aspect
                  they forget what the organisation does or is trying to do, or the type of risks they're willing
                  to take. Some organisations they take risks as part of their day-to-day activities so you need
                  to understand the risk appetite, you need to understand which risks you need to do something
                  about. Until you have a good understanding of the business you can't do a business impact
                    analysis so it's not just IT and technical you have to also understand the business.</p>
                      {/* <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-13-14+Final.mp4'
                          width='22rem'
                          controls={true}
                  /> */}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-13-14-1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>

          <Footer />
      </div>

  )
}

export default Qsguide;
