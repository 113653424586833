import React from 'react';
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1>Domain #2: Priority </h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <h4>Overview</h4>
                  <p>8 Videos. 8 Audio files. 33 key points</p>
                  <p>Average viewing time: 4 minutes 37 seconds</p>
                  <p>Recorded: October 13th 2020</p>
                  <h4>Domain #2: PRIORITY Checklist</h4>
                  <i>The difference between urgency and importancy</i>
                  <ol>
                      <li><a href='/MM2Triage'>Using Triage</a></li>
                      <li><a href='/MM2Triage'>How To Prioritise</a></li>
                      <li><a href='/MM2Methods'>What Method Should You Use?</a></li>
                      <li><a href='/MM2Methods'>Human Factors</a></li>
                      <li><a href='/MM2Assess'>Assess and Evaluate</a></li>
                      <li><a href='/MM2Assess'>Candidate Screening</a></li>
                      <li><a href='/MM2Employment'>Employment Agreements</a></li>
                      <li><a href='/MM2Employment'>Onboarding and Offboarding</a></li>
                      <li><a href='/MM2Review'>Review Threats & Sources</a></li>
                      <li><a href='/MM2Review'>Specific Vulnerabilities</a></li>
                      <li><a href='/MM2Safeguards'>Safeguards</a></li>
                      <li><a href='/MM2Safeguards'>IT & Non IT Related Threats</a></li>
                      <li><a href='/MM2Documenting'>Documenting Risks</a></li>
                      <li><a href='/MM2Documenting'>Business Impact Analysis</a></li>
                      <li><a href='/MM2SPOF'>Human Values & SPOF</a></li>
                      <li><a href='/MM2SPOF'>Essential Computations</a></li>
                  </ol>
              </div>
          </article>
          <Footer />
      </div>    
 
  )
}

export default Qsguide;
