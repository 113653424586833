import React from 'react';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
      <article className='flow'>
              <img className="article-main-image" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/taking-118X210-01.jpg" alt='one to one workshop' />
          <h1>ESORMA Quick Start Domain Guides</h1>
          <p className='lead'>Questionnaires to help team members engage with cyber security</p>
          <div className='article-body flow'>
              <p>
                      ESORMA is designed to describe a movement, that of ease, and simplification linked to practicality and results.
              </p>

              <p>
                      Core principles are action related, ESORMA's role is in guidance, hence the ESORMA Quick Start Guide Workshops are free to download from this page.
                      Please do not share, instead, Refer A Friend to this site so colleagues can register for the latest versions.
              </p>

              <p>
                      ESORMA workshops ensure you have all the business requirements covered fast, and are ideal for introducing a deeper understanding of security to the wider
                      employee base without the need for detailed or demanding technical knowledge. The focus on the business of cybersecurity is to maximise enterprise wide protection, not just IT.
              </p>

              <p>
                      The questionnaires are written to help delegates focus on their business needs rather than ESORMA. ESORMA provides the framework while the business drives the direction of
                      discussion and specific requirements. It is expected workshop leaders will have an understanding of cybersecurity and ESORMA to focus on finding ways to achieve business
                      objectives. Workshops may be run by the authors in person and virtually.
              </p>

              <p>
                      A business focussed approach makes for easier, more obvious, faster communication and understanding. You can expect to get support and budget approval more easily when you involve
                      the team and the team gets involved.
              </p>

              <p>
                      ESORMA is a plain English framework and architecture that links Governance, Risk and compliance with operations and IT.
              </p>

          </div>
       </article>


        <options>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer"
            href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/1+Scope.pdf'>
            <img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Scope PDF'/>Domain #1: Scope</a>
          </div>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/2+Priority.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Priority PDF'/>Domain #2: Priority</a>
              </div>
          </options>
          <options>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/3+Evaluate.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Evaluate PDF'/>Domain #3: Evaluate</a>
          </div>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/4+Enable.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Enable PDF'/>Domain #4: Enable</a>
              </div>
          </options>
          <options>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/5+Harden.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Harden PDF'/>Domain #5: Harden</a>
          </div>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/6+Monitor.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Monitor PDF'/>Domain #6: Monitor</a>
              </div>
          </options>
          <options>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/7+Operations.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Operations PDF'/>Domain #7: Operations</a>
          </div>
          <div className='col-md-3 text-center'>
            <a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/8+Compliance.pdf'><img className='pdf' src='https://s3.eu-west-2.amazonaws.com/esorma.com/quickstart/PDF.jpg' alt='Download Compliance PDF'/>Domain #8: Compliance</a>
          </div>

          </options>
          <Footer/>
      </div>

  )
}

export default Qsguide;
