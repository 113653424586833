import React from 'react';
import ReactPlayer from 'react-player'
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #7: Asset Location</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Another thing that needs to be listed in the asset register is locations and of course
        values, there are issues about GDPR keeping data within Europe or within your own country
        and not flying out to other regions with different security profiles. I guess you'll know
        about that but this is something that you need to check: where is that data in terms of
        external storage? Especially the advent of the cloud more and more accompanying suppliers
        are using the cloud so some of the systems that you buy or employ you may not realize on
        the face of it but they may be storing data outside of the EU or further away or in
        different locations than you you realize or expected so this needs to be reviewed and
        understood.</p>

                  <p>We need to be very clear: we actually need to trace as much as we can the path of the
                  data in terms of data sets that your organization uses to make sure that you really do
                  know where it is because every time the data moves it gives rise to a potential
                  opportunity for a breach because you know if it moves out of your control then that
        could be a problem asset value.</p>

                  <p>Number 12. Very important here, some people have found themselves listing the asset
                  value at cost when they bought the equipment but of course equipment values often increase
                  so the asset values need to be reassessed with some frequency so at least once a year. I
                  would suggest that different organizations have different views on it but the biggest
                  problem is when organizations don't do anything about it. Then one day it comes to the
                  cost of replacement they find they are underinsured or they just don't have the cash
        reserves ready and are surprised and perhaps even shocked.</p>

                  <p>In other cases, of course, the prices go down which can give them the opportunity and
                  access to have a replacement kit or just renew it because it suddenly becomes something
                  that's much more affordable and also as company suppliers develop they find ways to
                  enhance or increase their product offering so you may not need to buy a complete
        identical replacement and maybe now a better solution is available for you.</p>

                  <p>Any suggestions on these two points there Mustafa? Oh no you've uh you've covered
                  it quite well, yeah the asset locations and values definitely have to be in the asset
                  register together with listing of the assets. If you can list their locations it will
                  make your life much easier and obviously the value because depending on the value of
                  the asset that's going to determine how much effort or money you're going to spend on
        protecting those assets so yeah it's very important to have that in the register.</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++11-12+Asset+Locations+Asset+Values.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
          <Footer />  

        
      </div>    
 
  )
}

export default Qsguide;
