import React from 'react';
import ReactPlayer from 'react-player';
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #7: Risk Apetite</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>You can quickly start building big pictures in graphical formats and action plans so how do we do all this? Well typically we do it using things like KGIs, KPIs and there may be other critical factors or information that we need to collect around governance so KGI is a Key Goal Indicator so you have you set a goal there's a key goal maybe come to you from compliance. It may be a business issue, or it may be from governance,  whatever the goal is your business, your enterprise, you decide. Similarly for KPIs: Key Performance Indicators, so this might be just to say well the throughput should between, these figures but no higher than that and providing we're producing an amount of product or service that's in this bandwidth then that's happy life.</p>

                  <p>Everything's cool, if we're if we we go higher then maybe we're going to strain other parts of the business or the supply chain and if we go lower then there must be something stopping us there's something else that's gone wrong in the business for us not to be in this band this range. </p>

                  <p>So key goals is about key achievement KPIs is about managing progress and process over time which is something we want to do. KGIs and KPIs can provide evidence of continuous development and continuous security and even maturity. Metrics, numbers could provide great evidence for proof of security governance and this is something that you can focus on and easily achieving most businesses that the numbers already exist as there's already a throughput of something somewhere someone's being delivered or satisfied or sold to these are there are numbers there now they might ultimately not be the numbers you're looking for in the short term but there's numbers behind numbers. There are ways that we can start using this stuff.</p>

                  <p>KGIs and KPI so any type of metrics that you employ they make sure that you're keeping on top of things and it's something that senior management have to put in place. Governance is an important part of information security like we mentioned right at the beginning. If it's not done correctly it's gonna affect everything below it, so, if governance isn't done right management is gonna be lacking, and if management is lacking compliance is gonna be lacking throughout the organisation so it starts from the top. </p>

                  <p>Security is top-down there's no other way about it it has to be enforced, it has to be implemented from the top. How do you make sure it's in place? The goals are fine you've maybe met all your goals so you might have put controls in place your goal was here's a risk we need to put a control in place you've met your goal that's fine but how is that control performing? The continuous part is measured by KPIs the key performance indicators how or what are your security goals the other thing is what or how is security performing right now and that's the ongoing aspect of it if it's not performing you have to do something about it. You have to put something in place so you react to it and it ties neatly into what we just covered earlier the continuous improvement cycle. So you can measure things and then once you have the data available to you, you can do something about it where they are short for so yeah it's something that you have to do on an ongoing basis.</p>

                  <p>If you're not measuring you don't know where you are and chances are you've missed something or something is lacking and it's just not being, it's not within your radar as they say so that's everything to do with KGIs and KPIs like you said there's a lot more you can add to this you might even be implementing an IT governance framework like COBIT for instance, but again that's beyond the scope of this webinar there's a lot of things you can do the basics need to be in place though and that's part of the Enable domain in ESORMA to enable security, meet your goals and then keep an eye on their performance, on the performance of security is what's going to enable a secure enterprise. That neatly rounds up this webinar. </p>

                  <p>In this domain, the key question is: do you have metrics in place to measure results?</p>
                  {/*}    <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+7+Risk+Apetite.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+7+Risk+Apetite.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
