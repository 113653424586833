import React from 'react';
import ReactPlayer from 'react-player';
import './../../App.css';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #1: SCOPE, #10: Classification & Ownership</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>We touched a little bit on secret top-secret classified confidential different levels of
        classification these are the most common there are others. You can use those to determine
        controls so you know only certain people are certified let's say to be able to manage top
        secret information and in terms of and the word manage, manager, or management always
        reminds me of a classic scenario where many organizations managers have risen through the
        ranks and they used to have access or needed to have access to certain data and as they
        became a manager their access control system remained okay so they become the manager but
        they now have more access to everything and of course from a hacker's perspective the number
        one person they really want to get to all the time it's the person with the most access to
        the most data. Because, if they can hack or crack that person's account then they get access
        to a lot of data so part of the security role is to question why does the manager need to
        have top-secret access or confidential access or even classified access?</p>

                  <p>Is the manager actually processing any of that data the answer is in practice? No. Then
                  the line manager shouldn't have access to any of the data it's just an unnecessary risk.
                  Asset ownership is normally something that's worked out by the asset owner which is normally
                  a person who's defined as the owner. Without this then the company is the owner and that
                  would be very loose ownership but usually a person is identified as an asset owner who is
                  responsible for the data or data set and that person then defines the rights and chooses
                  who and what and when data sets can be managed or manipulated or edited or added to or
                  deleted or used, processed, in other words and that person, the asset owner should have
                  complete control on who that is. Now that asset owner would possibly probably value from
                  being supervised and from a security perspective you really do want to understand that the
                  asset owner has been really tight with that channel, that path of ownership as it goes
        through processing. Mustafa?</p>

                  <p>You've covered quite well classifications also need to be on the asset register.
                  Generally, I do find categories and classifications on registers that I've seen one thing
                  I have seen missing a lot is the owner in one column should have who the owner is. Data
                  ownership like you said unless there's someone responsible or has a responsibility for that
                  data set it's going to be overlooked and it might just become an issue, so once, when you
                  know who the owner is you can also check whether they're fully aware of who has access to
                  that data and whether they are happy with that or not. Again it's something that should be
                  done once you've done it initially it's just about keeping that up to date, keeping that
                  for data scoping you have to keep the asset register up to date. Owners change, people leave,
        move position, who's currently in charge?</p>

                  <p>You're right if people have more access than they need it's going to lead to problems
                  that's how some of the phishing or whaling attacks happen people have a lot of access to
                  their accounts and the other accounts are targeted and when they're targeted yeah people
                  end up taking a lot more data than they would have if there was proper categorisation and
                  ownership and classification in place and accounts were looked at in terms of what they
        should have access to and what they shouldn't have access to.</p>

                  <p>Yeah it's still a wide-ranging problem and it's still why we do get a lot of data leaks
                  happening. One of the interesting things about this area of data is in most cases this is
                  no cost security. Zero cost security is a management process you get to the staff of an
                  organisation and to certify or qualify whether certain data was top secret, secret,
                  confidential, classified, whatever system you want to use there are loads of systems you
                  could use but the top-secret, confidential, classified it's a simple and classic one and
                  then limiting access you really always already have the system but to do that and it's
                  often just not done and can magically occur because people are promoted from within and
                  they are reluctant to give up their former access but obviously this creates a forward
        risk so this is easy to explain easily for people to understand.</p>

                  <p>Process management, process control, security control are easily mapped out and it is
                  something you can change without any major expense in this area. The other thing which is
                  a key issue of mine anyway is to also minimize the amount of information. So when Accounts
                  are chasing for money why should the Accounts department know what operation took place or
                  be able to access medical information or business information about that organisation all
                  the Accounts need to have is access to an invoice date the amount, and who the seller is,
                  who the buyer is, invoice related information okay they might need to know a one-liner but
                  even that could be a code in such a way that we're not giving away information to someone
                  who might stumble across it or if we ring up and chasing money we inadvertently tell
                  someone else that they had a hip operation when the other person doesn't want them to
                  know. There are all sorts of little trip-ups which are all to do with someone such as
                  an operator giving away too much information in just simple ways just by saying things
        over the phone they can be very embarrassing and expensive.</p>
                      <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/1+Scope/ESORMA++Domain+%231++17-18+Classify+Data%2C+Asset+Ownership.mp4'
                          width='22rem'
                          controls={true}
                      />
                
              </div>
          </article>
          <Footer />  

        
      </div>    
 
  )
}

export default Qsguide;
