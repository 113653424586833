import React from 'react';
import './../../App.css';

function Nav() {

    return (
        <footer>
            <div className='columns'>
                <div>
                    <ul>
                        <li>
                            <a href="/ESORMA">
                                <span className='footername'>ESORMA<span className="logo-spot">.</span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://esorma.com/about.html">About us</a>
                        </li>
                        <li>
                            <a href="https://esorma.com/memberships.html">Memberships</a>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li>
                            <a href="https://esorma.com/Privacy.html">Privacy</a>
                        </li>
                        <li>
                            <a href="https://esorma.com/cookie-policy.html">Cookie Policy</a>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Esorma-114083720377568">Facebook</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/esorma-grc-community/">Linkedin</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/playlist?list=PLWFiKqsXfFEJzHh4D1912am74a3dZXf8m">Youtube</a>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li>
                            <a data-toggle="tooltip" target='_blank' rel="noreferrer" title="Check out the most common and recurring vulnerabilities" href='https://owasp.org'>OWASP</a>
                        </li>
                        <li>
                            <a data-toggle="tooltip" target='_blank' rel="noreferrer" title="The place to start for smaller companies" href='https://www.iasme.co.uk/cyberessentials/'>Cyber Essentials</a>
                        </li>
                        <li>
                            <a data-toggle="tooltip" target='_blank' rel="noreferrer" title="For smaller companies who also want to be certain they satisfy GDPR requirements" href='https://www.iasme.co.uk/the-iasme-standard/'>IASME Governance ( + GDPR)</a>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul>

                        <li>
                            <a data-toggle="tooltip" target='_blank' rel="noreferrer" title="For larger, internationally focused businesses who want to demonstrate they are serious about security" href='https://www.iso.org/standard/54534.html'>IS0 27001</a>
                        </li>
                        <li>
                            <a data-toggle="tooltip" target='_blank' rel="noreferrer" title="For larger International companies who work with the US or who already work towards NIST standards - considered the gold standard with over 1,630 control elements" href='https://nvd.nist.gov/800-53/Rev4/'>NIST 800-53 Rev 4</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="footer-copyright">&copy; 2022</div>
        </footer>
    )
}

export default Nav;
