import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #3: Evaluate. #4: Business Continuity Plan (BCP/DRP)</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>The thing we've all been waiting for: the Business Continuity Plan, ever, forever forwardly known as the business 'community' plan</p>

                  <p>Because it actually, funnily enough, is about the community to some extent but it's not, it's about continuity.</p>

                  <p>
                      Continuity is about maintaining a business, ensuring  your business is sustainable over time,  should things go wrong there are contingency plans in place so the business can continue.</p>

                  <p>We need a little perspective in this planning process and we need the help of staff. The Business Continuity Plan is an excellent method, system whatever you want to call it for involving colleagues because we often start with a need to understand what the business processes are. What's important to our colleagues and to our customers by actually interviewing them to ask them in their view what's critical and again remember they're going to have their own bias because everything is important to them that they do. With that in mind we want to be able to interview our staff and colleagues and potentially even our customers and we're coming to them from the perspective of we don't know everything, we can't know everything, because we are not necessarily operational, we're not, we're not doing everything. Obviously, we've got a pretty good idea about what's going on, but we can't actually know everything because if we leave it to ourselves to be the sole arbiter of business process or 'understander' of the business process the chances are we're gonna get it wrong.</p>

                  <p>Now, what happens when we get it wrong Mustafa? Well, why do you think they have the DRPs? Disaster Recovery Plans always mentioned when we talk about Business Continuity Plans? It will end up as a disaster. Staff, clients, everyone tries to find workarounds. They will always look for an easy way to do their job and get their work done basically and they don't care whether um they're bypassing a security feature they may not have been taught why, they might not know, so that's where the awareness comes in and we do talk a lot about awareness training and how much difference that can make.</p>

                  <p>This is a very important part and oddly enough 95 of businesses don't have any kind of Business Continuity Plan or Disaster Recovery Plan which is very odd especially as in most businesses it's very simple to do. The whole point of a BCP/DRP is to capture problems and to have a plan for disasters, even minor disasters so that we can maintain the continuity of the enterprise. Whatever business of the enterprise is in is whether it's you know educational, whether it's health, whether it's military, serious to somebody invariably at the very least the customer and the business owner.</p>

                  <p>We have put together an interview template which is available within the ESORMA portal. We keep mentioning ESORMA.com/freegifts.html. That's how you get into the portal. It's free, it comes with the book, we wrote it especially, and the whole idea of the interview template is to give you the opportunity to talk to colleagues and give them the opportunity to talk back to you. There are a number of caveats, again we talk about it in the book as well which is about bias. Bias is a big part of understanding when it comes to ESORMA and you need to be aware of bias. Everyone has different biases. Bias itself is not a bad thing, it naturally exists, but we can understand more about the business procedures by gaining information from people we work with from customers, it's like, why do we do it that way? It might be why do you want it in brown paper? I mean someone says you start looking at the out at the finished product then you start asking questions and it's not until you talk to clients, employees, and users that you start realising that certain aspects of the product are more important than you perceive them to be because someone at the end of the day or in the process has to use this product or service that you're producing and so they want it delivered to them in a way that's appropriate to their needs. These needs may not always be obvious to you and people do find workarounds so if we miss out on their needs, the employee will say well I'm just doing my job and you know we needed that, our client needed that, everyone knows that, no I don't not necessarily, especially if we haven't asked the question we don't know it. So we're looking for outliers or information that is unexpected for us to find. We should be able to look at a business process and see most of it, if not all of it in most cases.</p>

                  <p>We mentioned that many companies don't have it but even the ones that do, the larger organisations that might have a Business Continuity Plan, they tend to have a separate one for information security risk, they have a separate one, for continuing their production applying or offering services, and the compliance team might have their own, just to make sure that they don't get fined for a breach of a regulation or a standard that they need to. You end up with these silos and this is something that we talk about a lot as well, there's an overlap of work going on. This becomes inefficient. There's not much communication going on between these different functions in the organization so even if there are multiple business continuity plans rather than one overarching one it can still be a problem. We've come across this when we're consulting with organisations the left hand is not talking to the right hand in the organisation and the other thing like you mentioned.</p>

                  <p>There's things that the employees might know if you're going in as the CISO for instance you might interview managers but might forget to interview the person who's just working in admin and needs to send that email. Like you mentioned if they can't do their job or they may know of something where they are using it or cloud services IT don't even know about the IT department doesn't know about. That's why we say use this template we have in the portal. Interview, not just the managers, even people on the ground on the shop floor as they say and find out from them what really happens and where the risks really are so that would really help you build a more robust Business Continuity Plan I would say. And reduce risk definitely of you know risk reduction process creating more risk which of course it can do or even the business continuity plan having unintended consequences which is another thing.</p>

                  <p>One of the things that we also want to calculate is the maximum tolerable downtime, the MTD so this may be where failure occurs, it could be a car drives through the wall, falls off a local bridge or motorway, and flies into your, into a server room. Or, a gas tower that then explodes and blows the banks of a river, and now your server room and everything else is flooded. I mean disasters can occur far away to affect your business so it's not always about being in a secure environment that you're in. Its external factors as well so these are reasons why even Conid, need to understand or have a plan now of course whether we use it or not is another question.</p>

                  <p>To go to the last comment here: add a comment about a potential remedy our portal includes a very simple step-by-step Business Continuity Plan which you can download quickly for free. We also have a paid membership area where there's a course that we've developed over time which takes you a step by step through a detailed analysis and process of exactly how to build a Business Continuity Plan so you can run it over time. Our objective is to give you a quick short sharp plan and then to spend the rest of the time expanding that plan because if we spend all the time just working on the plan until that plan is completed it's unusable so actually what you want is you want a short quick plan put in place fast it may not be comprehensive, it may not be totally solid, but as even a partial plan is better than no plan. The majority of businesses or enterprises have zero plans something like only five percent. Just to have a partial plan is a good start and then we'll build that up for you we work on that together.</p>
                      {/*}<ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+4+BCP.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/3+Evaluate/Evaluate+4+BCP.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
