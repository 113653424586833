import React, { useEffect, useState, useReducer } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import logo from './logo.svg';
import './App.css';
import { listTodos,bymessageType } from './graphql/queries'


import Esorma from './pages/ESORMA/qsresources.js';
import GetInTouch from './pages/ESORMA/getintouch.js';
import Training from './pages/ESORMA/training.js';
import WelcomePage from './pages/holdingPage.js';
import QSresources from './pages/ESORMA/qsresources.js';
import QSwebinars from './pages/ESORMA/qswebinars.js';
import Qsguide from './pages/ESORMA/qsguide.js';
import ESORMATemplates from './pages/ESORMA/ESORMATemplates.js';
import ESORMATraining from './pages/ESORMA/ESORMATraining.js';
import ESORMAAudio from './pages/ESORMA/ESORMAAudio.js';
import MMScope from './pages/Webinars/MMScope.js';
import MMWhat from './pages/Webinars/MMWhat.js';
import MMMuscle from './pages/Webinars/MMMuscle.js';
import MMAspect from './pages/Webinars/MMAspect.js';
import MMAsset from './pages/Webinars/MMAsset.js';
import MMComm from './pages/Webinars/MMComm.js';
import MMALoc from './pages/Webinars/MMALoc.js';
import MMStaff from './pages/Webinars/MMStaff.js';
import MMData from './pages/Webinars/MMData.js';
import MMClassify from './pages/Webinars/MMClassify.js';
import MMTools from './pages/Webinars/MMTools.js';

import MM2Priority from './pages/Webinars/D2/MM2Priority.js';
import MM2Triage from './pages/Webinars/D2/MM2Triage.js';
import MM2Methods from './pages/Webinars/D2/MM2Methods.js';
import MM2Assess from './pages/Webinars/D2/MM2Assess.js';
import MM2Employment from './pages/Webinars/D2/MM2Employment.js';
import MM2Review from './pages/Webinars/D2/MM2Review.js';
import MM2Safeguards from './pages/Webinars/D2/MM2Safeguards.js';
import MM2Documenting from './pages/Webinars/D2/MM2Documenting.js';
import MM2SPOF from './pages/Webinars/D2/MM2SPOF.js';

import MM3Evaluate from './pages/Webinars/D3/MM3Evaluate.js';
import MM3Value from './pages/Webinars/D3/MM3Value.js';
import MM3BIA from './pages/Webinars/D3/MM3BIA.js';
import MM3Recovery from './pages/Webinars/D3/MM3Recovery.js';
import MM3BCP from './pages/Webinars/D3/MM3BCP.js';
import MM3What from './pages/Webinars/D3/MM3What.js';
import MM3Data from './pages/Webinars/D3/MM3Data.js';
import MM3Risk from './pages/Webinars/D3/MM3Risk.js';

import MM4Enable from './pages/Webinars/D4/MM4Enable.js';
import MM4Authority from './pages/Webinars/D4/MM4Authority.js';
import MM5Harden from './pages/Webinars/D5/MM5Harden.js';
import MM6Monitor from './pages/Webinars/D6/MM6Monitor.js';
import MM7Operations from './pages/Webinars/D7/MM7Operations.js';
import MM8Compliance from './pages/Webinars/D8/MM8Compliance.js';

import QSmastermind from './pages/ESORMA/qsmastermind.js';
import ChrisGunner from './pages/ESORMA/Mastermind/ChrisGunner.js';
import Mastermind from './pages/ESORMA/Mastermind/Mastermind.js';
import MichaelMacpherson from './pages/ESORMA/Mastermind/MichaelMacpherson.js';
import MikeOsman from './pages/ESORMA/Mastermind/MikeOsman.js';
import NigelRisner from './pages/ESORMA/Mastermind/NigelRisner.js';
import SimonWardley from './pages/ESORMA/Mastermind/SimonWardley.js';

import * as mutations from './graphql/mutations'
export const UserLevelContext = React.createContext();

export const isAuthenticated = async () => {
    try {
        return await Auth.currentSession().isValid()
    } catch (error) {
        return false;
    }
};

function levelReducer(userLevel, actionDomains) {
    switch (actionDomains.type) {
        case 'UPDATE_INPUT':
            return { inputText: actionDomains.data };
        default:
            return 0;
    }
}

function App() {
  const [userLevel, dispatchLevel] = useReducer(levelReducer,0)
  const [error, setError] = useState(null);
  const [username, setUsername] = useState(null);
  const [valuesToPass, setValuesToPass] = useState(null);
  const [userList, setUserList] = useState([]);

  const [noFind, setNoFind] =useState(false);

  const [nextToken, setNextToken] = useState('')
  const [nextNextToken, setNextNextToken] = useState()

  const [questions, setQuestions] = useState('')
  const [todos, setTodos] = useState([])

  const [findUserRecord, setFindUserRecord] = useState(0)
  const [nextSAToken, setNextSAToken] = useState('')
  const [nextNextSAToken, setNextNextSAToken] = useState()
  const showlog = 6;
  // 7 = points system
  // 8 = addrecord system
  // 6 = auth system
  // 0 = turnoff all reporting

  let mlevel = 0;
  let points = 0;
  let silver = 0;
  let gold = 0;

  //useEffect(() => { testNoFind(); },[todos,noFind])
  //useEffect(() => { next(); },[nextNextSAToken])

  useEffect(() => {
  try {
    setError(null);
     Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      if (showlog === 6) console.log('currentAuthenticatedUser username',user.username,'sub',user.attributes.sub, 'email',user.attributes.email)
      setUsername({'username':user.username, 'sub':user.attributes.sub, 'email':user.attributes.email})
    }).catch(err => setError(err));
  }
  catch (e) { setError(e); }
}, []);

{/*useEffect(() => {
    if (username) {
      if (showlog ===6) console.log('useEffect username',username.username,'userLevel',userLevel,'sub',username.sub)
      if (findUserRecord === 0) setFindUserRecord(2)
    }
  }, [username]);

useEffect(() => {
  if (username) {
    if (showlog === 8) console.log('findUserRecord username.username',username.username)
    const fetch = async () => {
      try {
        const variables1 = {
          nextToken: nextSAToken,
          filter: { messageType: { eq: "userRecord" }, owner: { eq: username.username}}
        }
        /* const result1 = await API.graphql(graphqlOperation(bymessageType, variables1) )
        const result1 = await API.graphql(graphqlOperation(bymessageType, { messageType: { eq: "userRecord" }, owner: { eq: username.username}}) )

        if (showlog === 8) console.log('findUserRecord result1',result1.data.bymessageType.items.length, result1) /* .data.listTodos.items
        if (result1.data.bymessageType.items.length === 0) {
          setNextNextSAToken(result1.data.bymessageType.nextToken)
        } else {
          setTodos(result1.data.bymessageType.items)
        }
      } catch (err) {
        if (showlog === 6) console.log('next error', err)
      }
    }
      fetch()
    }
  }, [nextSAToken, findUserRecord])


  function next() {
    if (showlog === 8) console.log('next nextNextSAToken')
    if ( nextNextSAToken !== null) {
      setNextSAToken(nextNextSAToken);
      setNoFind(false)
      if (showlog === 8) console.log('next nextNextSAToken')
    } else {
      if (todos.length === 0) {
        setNoFind(true)
      }
    }
  }


  function addItems() {
    if (showlog === 8) console.log('addItems todos',todos)
    if (todos.length === 0) {
      addUser()
    }
  }


  function testNoFind() {
    if (noFind === true ) {
      if (showlog === 8) console.log('noFind', noFind)
      addUser()
    }
  }

  async function addUser() {
    if (showlog === 8) console.log('got to addUser 1', username,'noFind',noFind)
    if (username !== null) {
      try {
          if (showlog === 8) console.log('got to addUser 2')
          const todo = { 'aws':'eu-west-2:e8fd22ab-25ec-4611-8062-636c7bdc37df','Id':username.sub,'mlevel': 0, 'points': 0, 'silver': 0, 'gold': 0,
          'messageType': 'userRecord', 'assignTo':'ESORMA','owner':username.username, 'email':username.email }
          if (showlog === 8) console.log('addUser todo :', todo)
          API.graphql(graphqlOperation(mutations.createTodo, {input: todo}))
          .then (
            setValuesToPass('1'),
            setTodos(todos.concat(todo)),
            setNoFind(false)
          )
      } catch (err) {
        //console.log(' useEffect error :', err)
      }
    }
  }
  */}

if (showlog === 6)  console.log('215 App username',username, 'todos',todos);



if (todos.length !== 0) {
  if (showlog === 8) console.log('got to todos length', todos)
   mlevel = todos[0]['mlevel'];  points = todos[0]['points'];  silver=todos[0]['silver'];  gold=todos[0]['gold'];
}

// if (username !== null && typeof points !== 'undefined' &&  todos.length !== 0) {
if (username !== null ) {
  const user    = username.username;
  const appemail   = username.email
  const id      = username.sub
  const log     = showlog

  if (showlog === 7) console.log('App mlevel',mlevel,'user',user,'id',id, 'appemail',appemail)
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' exact element={<WelcomePage appemail={appemail} mlevel={mlevel} id={id} user={user} points={points} silver={silver} gold={gold} log={log}/>} />
          <Route path='/QSresources' exact element={<QSresources appemail={appemail} mlevel={mlevel} id={id} user={user} points={points} silver={silver} gold={gold} log={log}/>} />
          <Route path='/QSwebinars' exact element={<QSwebinars appemail={appemail} mlevel={mlevel} id={id} user={user} points={points} silver={silver} gold={gold} log={log}/>} />
          <Route path='/ESORMATraining' exact element={<ESORMATraining appemail={appemail} mlevel={mlevel} id={id} user={user} points={points} silver={silver} gold={gold} log={log}/>} />
          <Route path='/QSmastermind' exact element={<QSmastermind appemail={appemail} mlevel={mlevel} id={id} user={user} points={points} silver={silver} gold={gold} log={log}/>} />

          <Route path='/getintouch' exact element={<GetInTouch />} />
          <Route path='/training' exact element={<Training />} />

          <Route path='/MM4Authority' exact element={<MM4Authority />} />
          <Route path='/MM4Enable' exact element={<MM4Enable />} />
          <Route path='/MM8Compliance' exact element={<MM8Compliance />} />
          <Route path='/MM7Operations' exact element={<MM7Operations />} />
          <Route path='/MM6Monitor' exact element={<MM6Monitor />} />
          <Route path='/MM5Harden' exact element={<MM5Harden />} />
          <Route path='/MM3Risk' exact element={<MM3Risk />} />
          <Route path='/MM3Data' exact element={<MM3Data />} />

          <Route path='/MM3What' exact element={<MM3What />} />
          <Route path='/MM3BCP' exact element={<MM3BCP />} />
          <Route path='/MM3Recovery' exact element={<MM3Recovery />} />
          <Route path='/MM3BIA' exact element={<MM3BIA />} />
          <Route path='/MM3Value' exact element={<MM3Value />} />
          <Route path='/MM3Evaluate' exact element={<MM3Data />} />
          <Route path='/MM2SPOF' exact element={<MM2SPOF />} />

          <Route path='/MM2Review' exact element={<MM2Review />} />
          <Route path='/MM2Documenting' exact element={<MM2Documenting />} />
          <Route path='/MM2Safeguards' exact element={<MM2Safeguards />} />
          <Route path='/MM2Employment' exact element={<MM2Employment />} />
          <Route path='/MM2Methods' exact element={<MM2Methods />} />
          <Route path='/MM2Assess' exact element={<MM2Assess />} />
          <Route path='/MM2Priority' exact element={<MM2Priority />} />

          <Route path='/MM2Triage' exact element={<MM2Triage />} />
          <Route path='/MMScope' exact element={<MMScope />} />
          <Route path='/MMWhat' exact element={<MMWhat />} />
          <Route path='/MMMuscle' exact element={<MMMuscle />} />
          <Route path='/MMAspect' exact element={<MMAspect />} />
          <Route path='/MMAsset' exact element={<MMAsset />} />
          <Route path='/MMComm' exact element={<MMComm />} />

          <Route path='/MMALoc' exact element={<MMALoc />} />
          <Route path='/MMStaff' exact element={<MMStaff />} />
          <Route path='/MMData' exact element={<MMData />} />
          <Route path='/MMClassify' exact element={<MMClassify />} />
          <Route path='/MMTools' exact element={<MMTools />} />
          <Route path='/esorma' exact element={<Esorma />} />
          <Route path='/QSguide' exact element={<Qsguide />} />

          <Route path='/ChrisGunner' exact element={<ChrisGunner />} />
          <Route path='/MichaelMacpherson' exact element={<MichaelMacpherson />} />
          <Route path='/MikeOsman' exact element={<MikeOsman />} />
          <Route path='/NigelRisner' exact element={<NigelRisner />} />
          <Route path='/SimonWardley' exact element={<SimonWardley />} />
        </Routes>
      </Router>
    </div>
  )
}
  return (
  <div>Checking you in...</div>)

}

export default withAuthenticator(App)


/*
old email list
aws eu-west-2:67236fb1-2563-4f99-bfff-f7fe8e852f6c Id beginswith contact:
*/
