import React from 'react';
import ReactPlayer from 'react-player'
import './../../../App.css';
import Nav from '../../structure/header.js';
import Footer from '../../structure/footer.js';

function Qsguide(props) {

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" className='article-main-image' />
              <h1 className='frontpage'>Domain #2: Priority, #6 Safeguards, IT & Non IT Related Threats</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='article-body flow'>
                  <p>Safeguards and IT and non-IT related threats, again when you've identified the kind of
                    threats that you're most likely to see that are prevalent within your organisation and you
                    know obviously you have time you can determine the best kind of safeguard or universal
                    safeguarding system so you know if you have multiple buildings to look after you know
                    maybe processes, procedures but also actual controls and safeguards that you put in place
                    that are common from building to building no matter the age of the building or the
                    difficulty of the building just so that everyone knows that they're there, that these
                    processes still exist everywhere that there is some conformity and it takes a certain
                    amount of education but it takes also a certain amount of decisions to be made that this
                    is how we're going to deal with things here. They tend to be predetermined and again
                    offer preferably additional protection for related things.</p>

                  <p>Again on number 12 IT and non IT related threats we've mentioned this, that you know
                  it's not just about the network there is this wider world of people communications,
                  locations, access, things, that are to do with you know protecting security and indeed
                  we spoke briefly about staff, and issues, and risks, human risks, but some of those staff
                  are maybe disgruntled, some of them maybe, you know just trying to achieve a workaround
                  so in other words some of those threats are unintentional, as well as some being
                  intentional, again you've got to understand or be able to determine or be prepared for
                    the idea that it can happen both ways as well</p>
                    {/*}  <ReactPlayer
                          className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-11-12+Final.mp4'
                          width='22rem'
                          controls={true}
                  />*/}
                  <ReactPlayer
                      className='react-player ml-2 mt-0 mb-2'
                      url='https://s3.eu-west-2.amazonaws.com/esorma.com/webinars/2+Priority/Esorma+Domain+%232++Priority-11-12-1.mp3'
                      width='22rem'
                      controls={true}
                  />
              </div>
          </article>
          <Footer />
      </div>

  )
}

export default Qsguide;
