import React from 'react';
import { Link } from "react-router-dom";
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';
import Points from '../../Components/Points'

function Qsguide(props) {
  const {id, user, email,log } = props
  const showlog = log
  let pts2add = 1; const action = "/QSmastermind";
  Points(props, action, pts2add)

  return (
    <div className='mt-5 mb-5'>
      <Nav />
          <article className='flow'>
              <img alt="masterminds behind a successful project" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/the-masterminds-behind-a-successful-project-7GZ9UDV+small.jpg" className='article-main-image' />
              <h1>Mastermind Interviews</h1>
              <p className='lead'>Individual CISOs share their career progression and concerns.</p>
              <div className='article-body flow'>
                  <p>
                      The conversations recorded directly with CISOs provide a range of interesting and different insights and perspective into some of the cybersecurity opportunities available.
                </p>
                  <p>
                      Nigel Risner, who is not a CISO, yet who has worked with many, reminds us the world is a Zoo. For us to make the most of it, it us up to us to become the Zookeeper.
                </p>

                  <p>
                      Michael MacPherson tells us about his experience and career path he took in order to get a role as a CISO and how he managed it based on his experience and wit, without having any specific high level certifications. Not that they were ignored.
                </p>

                  <p>
                      Chris Gunner tells us about how important it is to really listen in to the workforce, as they are the business. It is they who have to individually become more aware of cybersecurity and what they can do to prevent problems arising. Chris provides some great insights into the kind of problems he has faced and solutions he has found to work for his situation.
                </p>

                  <p>
                      Mike Osman, with top 100 company experience as a senior CISO, with a PHD in cybersecurity explains how important it is for our eyes to be open when applying for a CISO role. Mike introduces us to the kind of liabilities that may befall us if we are not careful. A reminder that during an interview we should endeavor to find out as much about the role as possible so we are certain of what we are agreeing to.
                </p>

              </div>
          </article>


        <options>
          <div className='col-md-3 text-center'>
            <Link to="/SimonWardley" alt="Click here to listen in to Simon Wardley">
              <img alt = 'Simon Wardley' className="img-fluid hoverable"
              src="https://s3.eu-west-2.amazonaws.com/esorma.com/Mastermind/SimonWardley/SImonWardley.gif"/>
            </Link>
          </div>
          <div className='col-md-3 text-center'>
            <Link to="/MichaelMacpherson" alt="Click here to listen in to Michael Macpherson">
              <img alt = 'Michael Macpherson' className="img-fluid hoverable"
              src="https://s3.eu-west-2.amazonaws.com/esorma.com/Mastermind/04_2020/Michael+Macpherson/MichaelMacpherson.gif"/>
            </Link>
          </div>
          </options>
          <options>
          <div className='col-md-3 text-center'>
            <Link to="/ChrisGunner" alt="Click here to listen in to Chris Gunner">
              <img alt = 'Chris Gunner' className= "img-fluid hoverable" src="https://s3.eu-west-2.amazonaws.com/esorma.com/Mastermind/05_2020/Chris+Gunner/Chris-Gunner.gif" />
            </Link>
          </div>
          <div className='col-md-3 text-center'>
            <Link to="/MikeOsman" alt="Click here to listen in to Mike Osman">
              <img alt = 'Mike Osman' className="img-fluid hoverable" src="https://s3.eu-west-2.amazonaws.com/esorma.com/Mastermind/04_2020/Mike+Osman/Guerrilla-Tactics-For-CISOs_ESORMA.gif"/>
            </Link>
          </div>
          </options>
          <options>
          <div className='col-md-3 text-center'>
            <Link to="/NigelRisner" alt="Click here to listen in to Nigel Risner">
              <img alt = 'Nige lRisner' className="img-fluid hoverable" src="https://s3.eu-west-2.amazonaws.com/esorma.com/Mastermind/04_2020/NigelRisner/Nigel-Risner.gif"/>
            </Link>
          </div>
          </options>
          <Footer />
      </div>

  )
}

export default Qsguide;
