import React, { useState,useEffect, useReducer, Fragment  } from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';
import { listTodos2,getTodo } from './../../graphql/queries'
import Points from '../../Components/Points'

function Qsguide(props) {
  const {id, user, email,log } = props
  const showlog = log
  let pts2add = 1; const action = "/ESORMATraining";
  Points(props, action, pts2add)

  showlog = 2;
  const [emaillist, setEmailList] = useState([]);
  const [todosQS, setTodosQS] = useState([]);
  const [startGetQuestions, setGetQuestions] = useState(0)
  const [nextQSToken, setNextQSToken] = useState(undefined);
  const [nextNextQSToken, setNextNextQSToken] = useState();
  const [eventItems, seteventItems] = useState([]);
  const [eventEmail, seteventEmail] = useState('');
  let counter = 0;
  if (startGetQuestions === 0) setGetQuestions(2)

  //useEffect(() => { console.log('eventItems',eventItems) },[eventItems])
  //useEffect(() => { fetchEventEmails(); }, []);
  useEffect(() => { nextQS(); },[nextNextQSToken])
  useEffect(() => { addQSItems(); },[todosQS])
  //useEffect(() => { console.log('nextQSToken',nextQSToken) },[nextQSToken])

  /*
  old email list
  aws 67236fb1-2563-4f99-bfff-f7fe8e852f6c Id beginswith contact: eu-west-2:0789dabf-aaa7-4bd1-9eaa-1768882d1f94
  */
  useEffect(() => {
    const fetch = async () => {
      try {
        const variables = {
          nextToken: nextQSToken,
          /*filter: {aws: {eq: "eu-west-2:0789dabf-aaa7-4bd1-9eaa-1768882d1f94"},
          Id: {beginsWith: "contact"}} //   eq: 'contact:8089951060953460' */

          filter: {and: [{aws: {eq: "eu-west-2:0789dabf-aaa7-4bd1-9eaa-1768882d1f94"}},
  {Id: {beginsWith: "contact"}}]},
        }
        const result = await API.graphql(
          graphqlOperation(listTodos2, variables)
        )
        setNextNextQSToken(result.data.listTodos.nextToken)
        if (!result.data.listTodos.nextToken) {
          if (showlog === 1) console.log('no next token eventItems',eventItems)
        } /* else {
          console.log(' next token ',result.data.listTodos.nextToken)
        } */
        // ther 2777 items in the database
        setTodosQS(result.data.listTodos.items)
      } catch (err) {
        console.log(err)
      }
    }
    fetch()
  }, [nextQSToken,startGetQuestions])

  function nextQS() {
    if ( nextNextQSToken !== null) {
      setNextQSToken(nextNextQSToken)
    }
  }
  function addQSItems() {
    if (showlog === 1) console.log('todosQS',todosQS)
    seteventItems(eventItems.concat(todosQS))
  }

  return (
      <div className='mt-5 mb-5'>
          <Nav />
          <article className='flow'>
              <img className="article-main-image" alt='cloud storage' src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/cloud-storage-118x210-01.jpg" />
                <h1 className='frontpage'>ESORMA Quick Start Guide Resources</h1>
                <p className='lead'>Tools, Plans, Case Studies, Workshops, Webinars and Masterminds all here to support the CISO community</p>
              <div className='article-body flow'>
                  <p>ESORMA at a glance</p>

                  <div>
                      <h2>Domain 1: Scope</h2>
                      <h3><a target='_blank' rel="noopener noreferrer" href='https://s3.eu-west-2.amazonaws.com/esorma.com/portal/ESORMA+resources/Information_Security_Risks_Assessment_A_Case_Study.pdf'>Risk Assessment Case Study</a></h3>
                  </div>
                </div>
          </article>
          <Footer />
      </div>
  )
}

export default Qsguide;

/*
export const listTodos2
  query ListTodos(
    $aws: String
    $Id: ModelIDKeyConditionInput
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTodos(
      aws: $aws
      Id: $Id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Id
        cEmail
      }
      nextToken
    }
  }
`;
*/
