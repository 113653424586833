/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendEmailMarketing = /* GraphQL */ `
  query SendEmailMarketing($data: AWSJSON) {
    sendEmailMarketing(data: $data)
  }
`;
export const sendEmailApplication = /* GraphQL */ `
  query SendEmailApplication($data: AWSJSON) {
    sendEmailApplication(data: $data)
  }
`;
export const sendEmailToWorkflow = /* GraphQL */ `
  query SendEmailToWorkflow($data: AWSJSON) {
    sendEmailToWorkflow(data: $data)
  }
`;
export const sendEmailToList = /* GraphQL */ `
  query SendEmailToList($data: AWSJSON) {
    sendEmailToList(data: $data)
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($aws: String!, $Id: ID!) {
    getTodo(aws: $aws, Id: $Id) {
      Id
      aws
      item
      active
      financeValues
      icon
      timingDays
      timingMonth
      timingDaysValue
      timingMonthValue
      valuesNext
      valuesMonth
      valuesName
      department
      target
      leadsEStimate
      conversionRate
      value
      name
      temp
      nickname
      cEmail
      email
      description
      surveyId
      trackId
      categoryId
      titleId
      eventId
      qA
      qId
      qGroup
      qName
      q1p1
      q1p2
      q2p1
      q2p2
      q3p1
      q3p2
      q4p1
      q4p2
      q5p1
      q5p2
      qNum
      qTitle
      qIntro1
      qIntro2
      qIntro3
      q1statememt
      q2statememt
      q3statememt
      q4statememt
      q1placeholder
      q2placeholder
      q3placeholder
      q4placeholder
      question
      qHeading
      qAnswer1
      qAnswer2_3
      qAnswer4_6
      qAnswer7_9
      qAnswer10
      suggestion
      CISSPref
      ESORMAref
      ISO27kref
      NISTref
      mlevel
      price
      rating
      owner
      dueByDate
      completedDate
      assignTo
      messageType
      ESORMA_Domain
      USER_Domain
      recurring
      deleted
      link
      attachment
      points
      trial
      silver
      gold
      settings {
        items {
          id
          SetID
          emailfrequency
          alerts
          Date1
          Date2
          Date3
          comment1
          comment2
          comment3
          num1
          num2
          num3
          recruiters
          services1
          services2
          services3
          ad1
          ad2
          ad3
          about1
          about2
          about3
          location1
          location2
          location3
          location4
          location5
          location6
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $aws: String
    $Id: ModelIDKeyConditionInput
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTodos(
      aws: $aws
      Id: $Id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Id
        aws
        item
        active
        financeValues
        icon
        timingDays
        timingMonth
        timingDaysValue
        timingMonthValue
        valuesNext
        valuesMonth
        valuesName
        department
        target
        leadsEStimate
        conversionRate
        value
        name
        temp
        nickname
        cEmail
        email
        description
        surveyId
        trackId
        categoryId
        titleId
        eventId
        qA
        qId
        qGroup
        qName
        q1p1
        q1p2
        q2p1
        q2p2
        q3p1
        q3p2
        q4p1
        q4p2
        q5p1
        q5p2
        qNum
        qTitle
        qIntro1
        qIntro2
        qIntro3
        q1statememt
        q2statememt
        q3statememt
        q4statememt
        q1placeholder
        q2placeholder
        q3placeholder
        q4placeholder
        question
        qHeading
        qAnswer1
        qAnswer2_3
        qAnswer4_6
        qAnswer7_9
        qAnswer10
        suggestion
        CISSPref
        ESORMAref
        ISO27kref
        NISTref
        mlevel
        price
        rating
        owner
        dueByDate
        completedDate
        assignTo
        messageType
        ESORMA_Domain
        USER_Domain
        recurring
        deleted
        link
        attachment
        points
        trial
        silver
        gold
        settings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTodos2 = /* GraphQL */ `
  query ListTodos(
    $aws: String
    $Id: ModelIDKeyConditionInput
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTodos(
      aws: $aws
      Id: $Id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Id
        cEmail
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      SetID
      emailfrequency
      alerts
      Date1
      Date2
      Date3
      comment1
      comment2
      comment3
      num1
      num2
      num3
      recruiters
      services1
      services2
      services3
      ad1
      ad2
      ad3
      about1
      about2
      about3
      location1
      location2
      location3
      location4
      location5
      location6
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        SetID
        emailfrequency
        alerts
        Date1
        Date2
        Date3
        comment1
        comment2
        comment3
        num1
        num2
        num3
        recruiters
        services1
        services2
        services3
        ad1
        ad2
        ad3
        about1
        about2
        about3
        location1
        location2
        location3
        location4
        location5
        location6
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      page
      refId
      owner
      points
      silver
      gold
      ADate
      createdAt
      updatedAt
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        page
        refId
        owner
        points
        silver
        gold
        ADate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      page
      ownerId
      owner
      message
      response
      responseDate
      FBDate
      Comments {
        items {
          id
          postID
          fbID
          content
          owner
          CDate
          ApprovedC
          Processed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        page
        ownerId
        owner
        message
        response
        responseDate
        FBDate
        Comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      author
      image1
      image2
      video
      video2
      youTube
      twitter
      category
      subcat1
      subcat2
      subcat3
      postType
      duration
      location
      locaTitle
      location2
      locaTitle2
      location3
      locaTitle3
      owner
      PDate
      descript
      descript2
      descript3
      ApprovedP
      blogID
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      comments {
        items {
          id
          postID
          fbID
          content
          owner
          CDate
          ApprovedC
          Processed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        author
        image1
        image2
        video
        video2
        youTube
        twitter
        category
        subcat1
        subcat2
        subcat3
        postType
        duration
        location
        locaTitle
        location2
        locaTitle2
        location3
        locaTitle3
        owner
        PDate
        descript
        descript2
        descript3
        ApprovedP
        blogID
        blog {
          id
          name
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      postID
      post {
        id
        title
        author
        image1
        image2
        video
        video2
        youTube
        twitter
        category
        subcat1
        subcat2
        subcat3
        postType
        duration
        location
        locaTitle
        location2
        locaTitle2
        location3
        locaTitle3
        owner
        PDate
        descript
        descript2
        descript3
        ApprovedP
        blogID
        blog {
          id
          name
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      fbID
      content
      owner
      CDate
      ApprovedC
      Processed
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        post {
          id
          title
          author
          image1
          image2
          video
          video2
          youTube
          twitter
          category
          subcat1
          subcat2
          subcat3
          postType
          duration
          location
          locaTitle
          location2
          locaTitle2
          location3
          locaTitle3
          owner
          PDate
          descript
          descript2
          descript3
          ApprovedP
          blogID
          createdAt
          updatedAt
        }
        fbID
        content
        owner
        CDate
        ApprovedC
        Processed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      name
      posts {
        items {
          id
          title
          author
          image1
          image2
          video
          video2
          youTube
          twitter
          category
          subcat1
          subcat2
          subcat3
          postType
          duration
          location
          locaTitle
          location2
          locaTitle2
          location3
          locaTitle3
          owner
          PDate
          descript
          descript2
          descript3
          ApprovedP
          blogID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      channelID
      author
      body
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channelID
        author
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTodoDueDate = /* GraphQL */ `
  query ByTodoDueDate(
    $owner: String
    $dueByDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTodoDueDate(
      owner: $owner
      dueByDate: $dueByDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        aws
        item
        active
        financeValues
        icon
        timingDays
        timingMonth
        timingDaysValue
        timingMonthValue
        valuesNext
        valuesMonth
        valuesName
        department
        target
        leadsEStimate
        conversionRate
        value
        name
        temp
        nickname
        cEmail
        email
        description
        surveyId
        trackId
        categoryId
        titleId
        eventId
        qA
        qId
        qGroup
        qName
        q1p1
        q1p2
        q2p1
        q2p2
        q3p1
        q3p2
        q4p1
        q4p2
        q5p1
        q5p2
        qNum
        qTitle
        qIntro1
        qIntro2
        qIntro3
        q1statememt
        q2statememt
        q3statememt
        q4statememt
        q1placeholder
        q2placeholder
        q3placeholder
        q4placeholder
        question
        qHeading
        qAnswer1
        qAnswer2_3
        qAnswer4_6
        qAnswer7_9
        qAnswer10
        suggestion
        CISSPref
        ESORMAref
        ISO27kref
        NISTref
        mlevel
        price
        rating
        owner
        dueByDate
        completedDate
        assignTo
        messageType
        ESORMA_Domain
        USER_Domain
        recurring
        deleted
        link
        attachment
        points
        trial
        silver
        gold
        settings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byDueByDate = /* GraphQL */ `
  query ByDueByDate(
    $dueByDate: Float
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byDueByDate(
      dueByDate: $dueByDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        aws
        item
        active
        financeValues
        icon
        timingDays
        timingMonth
        timingDaysValue
        timingMonthValue
        valuesNext
        valuesMonth
        valuesName
        department
        target
        leadsEStimate
        conversionRate
        value
        name
        temp
        nickname
        cEmail
        email
        description
        surveyId
        trackId
        categoryId
        titleId
        eventId
        qA
        qId
        qGroup
        qName
        q1p1
        q1p2
        q2p1
        q2p2
        q3p1
        q3p2
        q4p1
        q4p2
        q5p1
        q5p2
        qNum
        qTitle
        qIntro1
        qIntro2
        qIntro3
        q1statememt
        q2statememt
        q3statememt
        q4statememt
        q1placeholder
        q2placeholder
        q3placeholder
        q4placeholder
        question
        qHeading
        qAnswer1
        qAnswer2_3
        qAnswer4_6
        qAnswer7_9
        qAnswer10
        suggestion
        CISSPref
        ESORMAref
        ISO27kref
        NISTref
        mlevel
        price
        rating
        owner
        dueByDate
        completedDate
        assignTo
        messageType
        ESORMA_Domain
        USER_Domain
        recurring
        deleted
        link
        attachment
        points
        trial
        silver
        gold
        settings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bytitleId = /* GraphQL */ `
  query BytitleId(
    $titleId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bytitleId(
      titleId: $titleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        aws
        item
        active
        financeValues
        icon
        timingDays
        timingMonth
        timingDaysValue
        timingMonthValue
        valuesNext
        valuesMonth
        valuesName
        department
        target
        leadsEStimate
        conversionRate
        value
        name
        temp
        nickname
        cEmail
        email
        description
        surveyId
        trackId
        categoryId
        titleId
        eventId
        qA
        qId
        qGroup
        qName
        q1p1
        q1p2
        q2p1
        q2p2
        q3p1
        q3p2
        q4p1
        q4p2
        q5p1
        q5p2
        qNum
        qTitle
        qIntro1
        qIntro2
        qIntro3
        q1statememt
        q2statememt
        q3statememt
        q4statememt
        q1placeholder
        q2placeholder
        q3placeholder
        q4placeholder
        question
        qHeading
        qAnswer1
        qAnswer2_3
        qAnswer4_6
        qAnswer7_9
        qAnswer10
        suggestion
        CISSPref
        ESORMAref
        ISO27kref
        NISTref
        mlevel
        price
        rating
        owner
        dueByDate
        completedDate
        assignTo
        messageType
        ESORMA_Domain
        USER_Domain
        recurring
        deleted
        link
        attachment
        points
        trial
        silver
        gold
        settings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bymessageType = /* GraphQL */ `
  query BymessageType(
    $messageType: String
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bymessageType(
      messageType: $messageType
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        aws
        item
        active
        financeValues
        icon
        timingDays
        timingMonth
        timingDaysValue
        timingMonthValue
        valuesNext
        valuesMonth
        valuesName
        department
        target
        leadsEStimate
        conversionRate
        value
        name
        temp
        nickname
        cEmail
        email
        description
        surveyId
        trackId
        categoryId
        titleId
        eventId
        qA
        qId
        qGroup
        qName
        q1p1
        q1p2
        q2p1
        q2p2
        q3p1
        q3p2
        q4p1
        q4p2
        q5p1
        q5p2
        qNum
        qTitle
        qIntro1
        qIntro2
        qIntro3
        q1statememt
        q2statememt
        q3statememt
        q4statememt
        q1placeholder
        q2placeholder
        q3placeholder
        q4placeholder
        question
        qHeading
        qAnswer1
        qAnswer2_3
        qAnswer4_6
        qAnswer7_9
        qAnswer10
        suggestion
        CISSPref
        ESORMAref
        ISO27kref
        NISTref
        mlevel
        price
        rating
        owner
        dueByDate
        completedDate
        assignTo
        messageType
        ESORMA_Domain
        USER_Domain
        recurring
        deleted
        link
        attachment
        points
        trial
        silver
        gold
        settings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byassigned = /* GraphQL */ `
  query Byassigned(
    $messageType: String
    $assignTo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byassigned(
      messageType: $messageType
      assignTo: $assignTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        aws
        item
        active
        financeValues
        icon
        timingDays
        timingMonth
        timingDaysValue
        timingMonthValue
        valuesNext
        valuesMonth
        valuesName
        department
        target
        leadsEStimate
        conversionRate
        value
        name
        temp
        nickname
        cEmail
        email
        description
        surveyId
        trackId
        categoryId
        titleId
        eventId
        qA
        qId
        qGroup
        qName
        q1p1
        q1p2
        q2p1
        q2p2
        q3p1
        q3p2
        q4p1
        q4p2
        q5p1
        q5p2
        qNum
        qTitle
        qIntro1
        qIntro2
        qIntro3
        q1statememt
        q2statememt
        q3statememt
        q4statememt
        q1placeholder
        q2placeholder
        q3placeholder
        q4placeholder
        question
        qHeading
        qAnswer1
        qAnswer2_3
        qAnswer4_6
        qAnswer7_9
        qAnswer10
        suggestion
        CISSPref
        ESORMAref
        ISO27kref
        NISTref
        mlevel
        price
        rating
        owner
        dueByDate
        completedDate
        assignTo
        messageType
        ESORMA_Domain
        USER_Domain
        recurring
        deleted
        link
        attachment
        points
        trial
        silver
        gold
        settings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byADate = /* GraphQL */ `
  query ByADate(
    $owner: String
    $ADate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byADate(
      owner: $owner
      ADate: $ADate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        page
        refId
        owner
        points
        silver
        gold
        ADate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byActPage = /* GraphQL */ `
  query ByActPage(
    $owner: String
    $page: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byActPage(
      owner: $owner
      page: $page
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        page
        refId
        owner
        points
        silver
        gold
        ADate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byFeedbackDate = /* GraphQL */ `
  query ByFeedbackDate(
    $owner: String
    $FBDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byFeedbackDate(
      owner: $owner
      FBDate: $FBDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        page
        ownerId
        owner
        message
        response
        responseDate
        FBDate
        Comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCategory = /* GraphQL */ `
  query ByCategory(
    $category: String
    $PDate: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCategory(
      category: $category
      PDate: $PDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        author
        image1
        image2
        video
        video2
        youTube
        twitter
        category
        subcat1
        subcat2
        subcat3
        postType
        duration
        location
        locaTitle
        location2
        locaTitle2
        location3
        locaTitle3
        owner
        PDate
        descript
        descript2
        descript3
        ApprovedP
        blogID
        blog {
          id
          name
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByChannelID = /* GraphQL */ `
  query MessagesByChannelID(
    $channelID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChannelID(
      channelID: $channelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelID
        author
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
