import React from 'react';
import Nav from '../structure/header.js';
import Footer from '../structure/footer.js';
import Points from '../../Components/Points'

function Qsguide(props) {
  const {id, user, email,log } = props
  const showlog = log
  let pts2add = 1; const action = "/QSwebinars";
  Points(props, action, pts2add)

  return (
    <div className='mt-5 mb-5'>
      <Nav />
      <article className='flow'>
              <img className="article-main-image" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/chris-montgomery-small.jpg" alt='webinars' />
          <h1 >ESORMA Quick Start Webinars</h1>
          <p className='lead'>Recorded webinars that provide implementation guidance for CISOs</p>
          <div className='article-body flow'>

              <p>
                      The ideas behind ESORMA have been discussed for years and have largely stemmed from the delegates of cyber security training.
                      Most are aware of cyber security issues and how they develop yet are left to consider how to implement a cyber security program alone.
              </p>

              <p>
                      Most turn to existing standards and frameworks for guidance which existed before cyber security was a serious consideration the way it is today.
                      This has led to a range of big problems. Many organisations face the very difficult task of how to focus on hundreds of controls that may need to be applied
                      across hundreds and in some cases thousands of assets. This has led to a them and us, silo'd scenario where cyber security is considered as an exclusive IT domain.
              </p>

              <p>
                      Cyber security is often added on top of existing processes, which has led to cyber security being forced to comply within pre-existing processes
                      forcing unnecessary steps our adversaries do not have to consider. Many standards and frameworks are exclusively technically focused and do not consider
                      human interface and implementation needs. Straightforward concepts like working out the best place to start and managing a work plan is rarely considered in a standard for instance.
              </p>

              <p>
                      In many cases there are cyber security processes enshrined in standards and frameworks to cause unlimited depth of focus and unnecessary work and activity which in practice often
                      proves to be inpractical.
              </p>

              <p>
                      ESORMA on the other hand was developed exclusively with the practitioner in mind and with a 100% focus on cyber security, from the ground up. ESORMA uses plain english to describe
                      business objectives and requirements to ease understanding and facilitate rapid implementation in ways others can understand.
              </p>

              <p>
                      Since the ESORMA quick start book was published and training provided, the authors have received a lot of positive feedback and questions about implementation. In this series of
                      webinars the authors seek to answer those questions and consider other questions often asked by practitioners and to this end the authors believe these webinars
                      augment and add to the content of the book, and support the live training events.
              </p>

          </div>
       </article>
       <webinar className='flow'>
           <img className="article-main-image" alt="telescope" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/telescope.jpg" />
              <h1 className="frontpage">Domain #1, Scope</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>

                <p>
                        <a href='/MMScope'>Overview</a>
                </p>
                <p>
                    <a href='/MMWhat'>#2 Security + Governance</a>
                </p>
                  <p>
                      <a href='/MMMuscle'>#3 Muscle Memory</a>
                  </p>

                  <p>
                      <a href='/MMAspect'>#4 Every Aspect, Document Assets</a>
                  </p>
                  <p>
                      <a href='/MMAsset'>#5 Asset Protection and Department Registers</a>
                  </p>
                  <p>
                      <a href='/MMComm'>#6 Commission Asset Registers</a>
                  </p>

                  <p>
                      <a href='/MMALoc'>#7 Asset Locations</a>
                  </p>
                  <p>
                      <a href='/MMStaff'>#8 Staff As Assets & Data</a>
                  </p>
                  <p>
                      <a href='/MMData'>#9 Data = Value + Location</a>
                  </p>


                  <p>
                      <a href='/MMClassify'>#10 Classification & Ownership</a>
                  </p>
                  <p>
                      <a href='/MMTools'>#11 Tools & Geo Mapping</a>
                  </p>
           </div>
        </webinar>

          <webinar className='flow'>
              <img className="article-main-image" alt="professional priority decision" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/making-professionalism-a-priority-small.jpg" />
              <h1 className="frontpage">Domain #2, Priority</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>

                  <p>
                      <a href='/MM2Priority'>Overview</a>
                  </p>
                  <p>
                      <a href='/MM2Triage'>#1 Triage To Prioritise</a>
                  </p>
                  <p>
                      <a href='/MM2Methods'>#2 Methods and Human Factors</a>
                  </p>
                  <p>
                      <a href='/MM2Assess'>#3 Assess, Evaluate & Screen</a>
                  </p>
                  <p>
                      <a href='/MM2Employment'>#4 Employment Agreements, Onboarding & Offboarding</a>
                  </p>
                  <p>
                      <a href='/MM2Review'>#5 Review Threats, Sources & Vulnerabilitie</a>
                  </p>
                  <p>
                      <a href='/MM2Safeguards'>#6 Safeguards, IT & Non IT Related Threatsn</a>
                  </p>
                  <p>
                      <a href='/MM2Documenting'>#7 Documenting Risks & Business Impact Analysis</a>
                  </p>
                  <p>
                      <a href='/MM2SPOF'>#8 Human Values, SPOF & Essential Computations</a>
                  </p>
              </div>
          </webinar>

          <webinar className='flow'>
              <img className="article-main-image" alt="evaluate: child near gas tanks is good?" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Child+near+gas+tanks.jpg" />
              <h1 className="frontpage">Domain #3, Evaluate</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>

                  <p>
                      <a href='/MM3Evaluate'>Overview</a>
                  </p>
                  <p>
                      <a href='/MM3Value'>#1 The value of Governance, Risk & Compliance</a>
                  </p>
                  <p>
                      <a href='/MM3BIA'>#2 Business Impact Analysis</a>
                  </p>
                  <p>
                      <a href='/MM3Recovery'>#3 Recovery Time Objectives (RTOs)</a>
                  </p>
                  <p>
                      <a href='/MM3BCP'>#4 Business Continuity Plan (BCP/DRP)</a>
                  </p>
                  <p>
                      <a href='/MM3What'>#5 What process is undertaken by whom?</a>
                  </p>
                  <p>
                      <a href='/MM3Data'>#6 What is your data lifecycle?</a>
                  </p>
                  <p>
                      <a href='/MM3Risk'>#7: Risk Apetite</a>
                  </p>

              </div>
          </webinar>

          <webinar className='flow'>
              <img className="article-main-image" alt="enable: showing another what to do" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Enable.jpg"  />
              <h1 className="frontpage">Domain #4: Enable</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/MM4Enable'>Overview</a>
                  </p>
                  <p>
                      <a href='/MM4Authority'>Authority Structure</a>
                  </p>
              </div>
          </webinar>

          <webinar className='flow'>
              <img className="article-main-image" alt="Harden: man wearing hard hat" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/protect+portrait+small.jpg" />
              <h1 className="frontpage">Domain #5: Harden</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/MM5Harden'>Overview</a>
                  </p>
              </div>
          </webinar>

          <webinar className='flow'>
              <img className="article-main-image" alt="monitor: man working on computer" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/monitor-man-working-on-computer-in-it-office-HDEL6LZ.jpg" />
              <h1 className="frontpage">Domain #6: Monitor</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/MM6Monitor'>Overview</a>
                  </p>
              </div>
          </webinar>

          <webinar className='flow'>
              <img className="article-main-image" alt="operations: man operating PC" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/Operations-i71ZRcnqqvw-small.jpg" />
              <h1 className="frontpage">Domain #7: Operations</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/MM7Operations'>Overview</a>
                  </p>
              </div>
          </webinar>
          <webinar className='flow'>
              <img className="article-main-image" alt="compliance: audit work" src="https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/compliance-auditor-82JHLUM+small.jpg" />
              <h1 className="frontpage">Domain #8: Compliance</h1>
              <p className='lead'>CISO implementation support</p>
              <div className='webinar-body flow'>
                  <p>
                      <a href='/MM8Compliance'>Overview</a>
                  </p>
              </div>
          </webinar>
          <Footer />
      </div>
  )
}

export default Qsguide;
