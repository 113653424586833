import { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import { listActivities } from '../graphql/queries'
import { UserLevelContext } from '../App.js'

function Points(props, action, pts2add) {
  const {id, user, appemail,log, points, mlevel, silver, gold } = props
  {/* if (!points) { let points= 0 };
  if (log === 7) console.log('Points.js',points,'id',id,'user',user,'log',log,'action',action,'pts2add',pts2add,'appemail','appemail',appemail,'mlevel', mlevel, 'silver',silver, 'gold',gold)
  // debounce required as points called three times.
  const showlog = log;
  const [pointsData, setPointsData] = useState('')
  const [userRecord, setUserRecord] = useState('')
  const [findPage, setFindPage] = useState('')
  const {dispatchLevel} = useContext(UserLevelContext)

  useEffect(() => {
    if (showlog === 1) console.log('link',action)
    const today = new Date().getTime() / 1000
    setPointsData({ 'owner':props.user, 'page':action, 'refId' :id , 'points': pts2add, 'ADate': today})
    setUserRecord({ 'id':id , 'points': points + pts2add })
  }, [])

  useEffect(() => {
    if (pointsData && userRecord) { findpoints() }
  }, [userRecord, pointsData])

  useEffect(() => {
    if (findPage.length >= 1) updatePoints()
  }, [findPage])

    // Add points to activity
    async function findpoints() {
      // find user & page if exists read points otherwise create a new page
      try {
        const todoData = await API.graphql(graphqlOperation(listActivities, {
          filter : {owner: {eq: props.user}, page: {eq: action}}}))
        const pageFound = todoData.data.listActivities.items
        // console.log('pageFound 1', pageFound)
        setFindPage(pageFound)
        if (pageFound.length === 0) createPage()
      } catch (err) {
        console.log(err)
      }
    }

    async function updatePoints() {
      const today = new Date().getTime() / 1000
      let todo = { ...findPage}
      const newpts = todo[0].points+pts2add
      const newId   = todo[0].id
      //console.log('newpts',newpts,'id',newId)
      try {
        API.graphql(graphqlOperation(mutations.updateActivity, {input: {id: newId, points: newpts, ADate: today}}))
        .then (
          modifyUserRecord()
        )
      } catch (err) {
        console.log(' updatePoints error :', err)
      }
    }

    async function createPage() {
      //console.log('createPage',pointsData)
      try {
        API.graphql(graphqlOperation(mutations.createActivity, {input: pointsData}))
        .then (
          modifyUserRecord()
        )
      } catch (err) {
        console.log(' createPage error :', err)
      }
    }

    // Add Points to userRecord
    async function modifyUserRecord() {
      if (showlog === 2) console.log('modifyUserRecord userRecord:', userRecord)
        try {
          await API.graphql(graphqlOperation(mutations.updateTodo, {input: userRecord}))
          forceUserLookup()
        } catch (err) {
          if (showlog === 1) console.log('error modifyUserRecord:', err)
        }
    }

    // run dispatch to force database re-read from App.js
    function forceUserLookup() {
      if (showlog === 2) console.log('got to forceUserLookup')
      dispatchLevel({ type: 'UPDATE_INPUT', data: pts2add});
    }
    */}
}

export default Points;
